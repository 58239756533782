.button,
.button2 {
  font-family: "Inter", sans-serif;
  box-sizing: border-box;
  padding: 0 29px;
  border: none;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  border-radius: 4px;

  &:disabled {
    cursor: not-allowed;
    color: gray;
  }

  &-data {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;

    span {
      margin-left: 8px;
    }

    .span2 {
      margin: 0 8px;
    }

    a {
      text-decoration: none;
      color: gray;
    }
  }
}

.button {
  color: gray;

  &:disabled {
    color: #fff;
    background: rgba(164, 167, 183, 0.4) !important;
    cursor: not-allowed;
  }
}

.add-admin-btn {
  border: none;
  background: none;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.117188px;
  color: #0a74dc;
  span {
    text-decoration-line: underline;
  }
}
