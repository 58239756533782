.navbar {
  position: fixed;
  z-index: 4;
  height: 64px;
  width: 100%;
  grid-area: header;
  box-sizing: border-box;
  background: #ffffff;

  #nav-check {
    display: none;
  }

  &-left {
    display: none;
    padding-left: 30px;
    cursor: pointer;
    padding-left: 30px;

    &-item1,
    &-item2,
    &-item3 {
      width: 35px;
      height: 3px;
      margin-bottom: 7px;
      background: #031434;
    }
  }
  box-shadow: 0px 2px 6px rgba(229, 229, 229, 0.3);

  &-right {
    padding-right: 38px;
    padding-top: 5px;
    float: right;
    display: flex;
    height: 100%;
    align-items: center;
    grid-column-gap: 28px;
    &-bell {
      width: 24px;
      height: 24px;
      img {
        width: 100%;
        height: 100%;
      }
    }

    &-profile {
      width: 30px;
      height: 30px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
    &-hr {
      width: 1px;
      height: 25px;
      background: $table-border;
      border: none;
    }
    &-user {
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      color: #303b54;
    }
  }
}
.dropdown {
  transform: translateY(2%);
  transition-delay: 0s, 0s, 0.3s;
  position: absolute;
  top: 90%;
  right: 20px;
  width: 144px;
  background: #ffffff;
  box-sizing: border-box;
  border-top: none;
  box-shadow: 0px 0px 12px rgba(164, 167, 183, 0.2);
  border-radius: 2px;
  padding: 10px 9px;

  &-item {
    height: 50%;
    width: 100%;
    padding: 8px 15px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;

    &-image {
      img {
        opacity: 0.7;
      }
    }

    &-text {
      margin-left: 8px;
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.01em;
      color: $deep-dark-color;
    }

    &:hover {
      background: rgba(13, 47, 161, 0.08);

      &-image {
        img {
          opacity: 1;
          filter: grayscale(100%);
        }
      }
    }

    &:hover img {
      opacity: 1;
      filter: grayscale(100%);
    }
  }
}

.drop-btn-img {
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .navbar-left {
    display: block;
    padding-top: 5px;
  }
  .navbar-right {
    padding-right: 30px;
  }
  // .navbar #nav-check:checked ~ .navbar {
  //   padding-left: 0;
  // }
  // .navbar #nav-check:checked ~ .sidebar2 {
  //   display: block;
  //   z-index: 10;
  // }

  .navbar .navbar-right-user-names {
    margin-left: 20px;
  }

  .navbar .navbar-right-notif {
    margin-left: 10px;
  }
}
