.checkboxes-title2 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: $deep-dark-color;
  margin-bottom: 16px;
}

.facility-container {
  height: fit-content;
  width: 100%;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 32px;
  box-sizing: border-box;
}

.facility-category {
  position: relative;

  &-delete {
    position: absolute;
    display: flex;
    font-size: 16px;
    right: -30px;
    top: -10px;
    cursor: pointer;
    width: 25px;
    height: 25px;
    align-items: center;
    justify-content: center;
    background: rgba(177, 188, 199, 0.3);
    color: #b0b9c8;
    border-radius: 50%;
    float: right;
  }
}

.optionListContainer {
  position: absolute;
  width: 100%;
  background: #fff;
  border-radius: 4px;
  margin-top: 1px;
  z-index: 100 !important;
}