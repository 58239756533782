.appointments {
  width: 100%;
  height: 100%;
}

.appointment-header {
  margin-top: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &-image {
    img {
      width: 100%;
      height: 100%;
    }
  }

  &-left {
    display: flex;
    border-radius: 4px;
    width: 64px;

    &-single,
    &-single2 {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    &-single {
      background: rgba(0, 220, 180, 0.1);
    }

    &-single2 {
      background: rgba(229, 229, 229, 0.3);

      .appointment-header-image {
        img {
          filter: grayscale(100%);
          opacity: 0.5;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.appointment-grid2 {
  margin-top: 24px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 24px;
}

.appointment-grid3 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 24px;
}

.appointment-grid {
  margin-top: 24px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 24px;

  &-section,
  &-patient-section {
    width: 100%;
    min-height: 281px;
    background: rgb(255, 255, 255);
    border-radius: 4px;
    padding: 24px;
    padding-bottom: 0;
    box-sizing: border-box;
    border: 1px solid #75747430;

    &-top {
      margin-bottom: 16px;

      .header {
        display: flex;
        align-items: center;
        margin-bottom: 25px;


        &-image {
          width: 36px;
          height: 36px;
          margin-top: -1rem;

          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }

        h2 {
          margin-left: 12px;
          font-family: "Inter", sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 24px;
          color: $deep-dark-color;
          text-transform: capitalize;
          margin-bottom: 0;
        }
        h3 {
          color: $light-dark-color;
          margin-left: 14px;
          font-weight: 400;
        }
      }

      .body {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;
        min-height: 150px;
        overflow: auto;

        &-item {
          p {
            font-family: "Inter", sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 24px;
            color: $header-light;
            margin-bottom: 0;
          }

          h3 {
            font-family: "Inter", sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            line-height: 24px;
            color: $light-dark-color;
            margin-bottom: 0;
          }
        }
      }
    }

    &-bottom {
      height: 64px;
      width: 100%;
      border-top: 1px solid $table-border;
      display: flex;
      align-items: center;
      justify-content: center;

      &-item {
        width: 100%;
        display: flex;
        justify-content: space-between;
        position: relative;

        span {
          font-family: "Inter", sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.005em;
        }

        .image {
          position: relative;

          img {
            cursor: pointer;
          }
        }
      }
    }
  }

  &-patient-section {
    min-height: 100px;

    &-top {
      .body {
        min-height: 50px;
      }
    }
  }

  &-section2 {
    width: 100%;
    min-height: 270px;
    padding: 20px;
    padding-bottom: 0;
    box-sizing: border-box;
    background: rgba(177, 188, 199, 0.08);

    &-top {
      margin-bottom: 13px;

      .header {
        display: flex;
        align-items: center;
        margin-bottom: 13px;

        &-image {
          width: 31.3px;
          height: 31.3px;
          border-radius: 50%;

          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }

        h2 {
          margin-left: 10px;
          font-family: "Inter", sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 13.913px;
          line-height: 21px;
          color: $deep-dark-color;
          margin-bottom: 0;
        }
      }

      .body {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 17px;
        min-height: 120px;
        overflow: auto;

        &-item {
          p {
            font-family: "Inter", sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 6px;
            line-height: 21px;
            color: $header-light;
            margin-bottom: 0;
          }

          h3 {
            font-family: "Inter", sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 8px;
            line-height: 21px;
            color: $light-dark-color;
            margin-bottom: 0;
          }
        }
      }
    }

    &-bottom {
      height: 64px;
      width: 100%;
      border-top: 1px solid $table-border;
      display: flex;
      align-items: center;
      justify-content: center;

      &-item {
        width: 100%;
        display: flex;
        justify-content: space-between;
        position: relative;

        span {
          font-family: "Inter", sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: 0.005em;
        }

        .image {
          position: relative;

          img {
            cursor: pointer;
          }
        }
      }
    }
  }
}

.appointment-grid-section-bottom-item .image > .table-dropdown {
  right: -10px;
}

@media screen and (max-width: 768px) {
  .appointment-header {
    margin-top: 32px;
    display: grid;
    align-items: center;
    justify-content: space-between;
  }

  .appointment-grid {
    margin-top: 24px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 24px;
  }

  .appointment-grid-section-bottom-item .image > .table-dropdown {
    right: 0;
  }

  .appointment-grid2,
  .appointment-grid3 {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 24px;
  }
}
