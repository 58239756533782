.sidebar {
  border-right: 1px solid #5f5f5f2b;
}

.sidebar,
.sidebar2 {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 272px;
  height: 100%;
  background: #fff;
  font-family: "Inter", sans-serif;
  padding: 24px 0;
  z-index: 5;
  box-sizing: border-box;

  &-logo {
    width: 114px;
    height: 42px;
    margin-left: 19px;
    box-sizing: border-box;
    // display: flex;
    // align-items: center;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &-content {
    margin-top: -26px;

    &-items {
      font-weight: 600;
      font-style: normal;
      font-family: "Inter", sans-serif;
      font-size: 14px;
      text-transform: uppercase;
      // color: rgba(255, 255, 255, 0.3);
      color: #5f5f5f;
      margin: 35px 0px 0px 40px;
      padding: 0px 0px 0px 10px;
    }

    &-icon {
      width: 18px;
      height: 18px;

      img {
        width: 100%;
        height: 100%;
        filter: opacity(0.2);
      }
    }

    .sidebar-item,
    .sidebar2-item {
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: normal;
      font-family: "Inter", sans-serif;
      font-size: 14px;
      font-weight: 300;
      // line-height: 18px;
      grid-column-gap: 15px;
      margin-left: 25px;
      padding: 0px 0px 0px 23px;
      // color: rgba(255, 255, 255, 0.5);
      color: #000000;
    }
    &-list {
      display: grid;
      grid-row-gap: 22px;
    }
  }
}

.sidebar-content {
  margin-top: 0.5rem;
  margin-bottom: 5rem;
  margin-left: -2rem;
}


.sidebar-content-isactive {
  .sidebar-item,
  .sidebar2-item {
    display: flex;
    // width: 85%;
    // height: 48px;
    // font-style: normal;
    font-weight: 600;
    // font-size: 16px;
    color: $light-blue;
    align-items: center;
    border-radius: 4px;

    .sidebar-content-icon {
      width: 18px;
      height: 18px;

      img {
        width: 100%;
        height: 100%;
        color: #5f5f5f;
        // filter: none;
      }
    }
  }
}

.sidebar2-wrap {
  display: flex !important;
  justify-content: space-around !important;
  align-items: center !important;
}

.sidebar2 {
  display: none;

  &-left-item1,
  &-left-item2 {
    width: 35px;
    height: 3px;
    margin-bottom: 5px;
    background: $deep-blue;
  }

  .sidebar2-left {
    .close {
      position: relative;
      width: 30px;
      height: 30px;
    }

    .close:before,
    .close:after {
      position: absolute;
      left: 15px;
      content: " ";
      height: 33px;
      width: 3px;
      background-color: #fff;
    }

    .close:before {
      transform: rotate(45deg);
    }

    .close:after {
      transform: rotate(-45deg);
    }
  }
}

@media screen and (max-width: 768px) {
  .sidebar {
    display: none;
  }
}
