.orders {
  width: 100%;
  height: 100%;
}

.order-details {
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  grid-column-gap: 40px;

  &-title {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 24px;
    color: $deep-dark-color;
    margin-bottom: 10px;
    margin-top: 25px;
  }
}

.orders > .table-dropdown {
  right: -5px;
}

@media screen and (max-width: 768px) {
}
