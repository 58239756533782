.upload-csv-modal{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    // height: 50vh;
    background-color: #fff;
    z-index: 6;
    // padding: 4rem 2.2rem;
    text-align: center;
    .csv-container {
        padding: 4rem;
    }
    h2 {
        margin-bottom: 2rem;
    }

    p {
        font-weight: 400;
        margin-bottom: 4rem;
        span {
            font-weight: bold;
        }
    }
    .button-container {
        display: flex;
        width: 100%;
        button {
            flex: 0 0 50%;
            padding: 1.5rem 0;
            outline: none;
            border: none;
            // background: transparent;
        }
        
        button:first-child{
            background-color: #4470e9;
            color: white;
        }
    }

}