.upload-image {
  display: flex;
  height: 51px;
  width: 100%;
  align-items: center;

  &-icon {
    width: 51px;
    height: 51px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }

  &-text {
    margin-left: 16px;
    position: relative;

    .custom-file-input {
      position: absolute;
      top: 0;
      opacity: 0;
      cursor: pointer;
    }

    .custom-file-input::-webkit-file-upload-button {
      visibility: hidden;
    }
  }

  h2 {
    cursor: pointer;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.02em;
    text-decoration-line: underline;
    color: $deep-grey;
    margin-bottom: 0;
  }
}

.product {
  width: 100%;
  height: 100%;

  &-header {
    &-top {
      display: flex;
      margin-bottom: 43px;
      align-items: center;

      &-image {
        width: 56px;
        height: 56px;
        margin-right: 16px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      &-text {
        h2 {
          font-family: "Inter", sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: $deep-dark-color;
          margin-bottom: 0;
        }

        p {
          font-family: "Inter", sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 130%;
          letter-spacing: -0.02em;
          color: $table-header;
          margin-bottom: 0;
        }
      }
    }

    &-bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 40px;

      &-wrapper {
        display: flex;

        .wrapper-button,
        .wrapper-button2 {
          width: 165px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: "Inter", sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: -0.01em;
          cursor: pointer;
        }

        .wrapper-button {
          background: #fff;
          color: $table-header;
        }

        .wrapper-button2 {
          background: #dff0ff;
          border-radius: 16px;
          color: $deep-dark-color;
        }
      }
    }
  }

  &-body {
    margin-top: 32px;
    width: 100%;
    min-height: 450px;
    background: #ffffff;
    border: 1px solid rgba(164, 167, 183, 0.4);
    box-sizing: border-box;
    border-radius: 4px;
    padding: 40px 32px;
    box-sizing: border-box;
  }

  &-summary {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 24px;

    .product-image-container {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 17px;
    }

    &-left {
      display: flex;
      flex-direction: column;

      &-image,
      &-image2 {
        height: 248px;
        width: 100%;
        border-radius: 4px;
        position: relative;
        cursor: pointer;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          border-radius: 4px;
        }
      }

      &-image2 {
        display: flex;
        flex-direction: column;
        background: rgba(229, 229, 229, 0.3);
        align-items: center;
        justify-content: center;
        position: relative;

        &-icon {
          width: 20px;
          height: 20px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        span {
          font-family: "Inter", sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: -0.01em;
          text-decoration-line: underline;
          color: #a4a7b7;
          margin-top: 4px;
        }

        .custom-file-input {
          position: absolute;
          opacity: 0;
          width: 100%;
          cursor: pointer;
        }

        .custom-file-input::-webkit-file-upload-button {
          visibility: hidden;
        }
      }

      &-image:hover ~ .overlay {
        visibility: visible;
      }

      .overlay-holder:hover > .overlay {
        display: flex;
        visibility: visible;
      }

      .overlay-holder {
        position: relative;
      }

      .overlay {
        position: absolute;
        height: 248px;
        width: 100%;
        background: rgba(48, 59, 84, 0.44);
        border-radius: 4px;
        visibility: hidden;
        top: 0px;
        left: 0px;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          font-family: "Inter", sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: -0.01em;
          text-decoration-line: underline;
          color: #ffffff;
          cursor: pointer;
        }

        .custom-file-input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
        }

        .custom-file-input::-webkit-file-upload-button {
          visibility: hidden;
        }
      }

      .divider {
        background: $table-border;
        width: 100%;
        height: 1px;
        margin: 32px 0;
      }

      &-inputs {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-row-gap: 60px;
        column-gap: 20px;
      }
    }

    &-right {
      width: 100%;
      min-height: 476px;
      background: rgba(177, 188, 199, 0.08);
      padding: 32px 16px;
      box-sizing: border-box;
    }
  }
}

.add-product-btn {
  padding-bottom: 40px;
}

@media screen and (max-width: 768px) {
  .product-header-bottom {
    height: fit-content;
    display: grid;
    grid-gap: 20px;
  }

  .product-body {
    padding: 20px;
  }

  .product-summary {
    grid-template-columns: 1fr;
  }

  .product-summary .product-image-container {
    grid-template-columns: 1fr;
    grid-gap: 17px;
  }

  .product-summary-left-inputs {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 30px;
    grid-column-gap: 20px;
    column-gap: 20px;
  }
}
