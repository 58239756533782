.blog {
  &-post {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 38px;

    &-left {
      &-title {
        width: 90%;
      }

      &-image {
        width: 100%;
        min-height: 249px;
        background: rgba(215, 229, 243, 0.19);
        border-radius: 4px;
        padding: 24px;
        box-sizing: border-box;
        margin-top: 24px;
        position: relative;
        top: 35px;

        h2 {
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          color: $light-blue;
          margin-bottom: 0;
        }

        &-div {
          display: grid;
          grid-template-columns: 0.8fr 3fr;
          grid-gap: 43px;
          width: 100%;
          align-items: center;
          margin-top: 24px;

          h4 {
            font-family: "Inter", sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: $light-dark-color;
            margin-bottom: 0;
          }

          .dashed-div-container {
            width: 100%;
            height: 100%;
          }

          .dashed-div {
            border: 2px dashed rgba(177, 188, 199, 0.6);
            border-radius: 4px;
            height: 50px;
            padding: 18px;
            width: 100%;
            box-sizing: border-box;
            display: flex;
            align-items: center;

            &-icon {
              width: 22px;
              height: 22px;

              img {
                width: 100%;
                height: 100%;
              }
            }

            span {
              margin-left: 20px;
              margin-right: 4px;
              font-family: "Inter", sans-serif;
              font-style: normal;
              font-weight: normal;
              font-size: 16px;
              line-height: 24px;
              color: $light-blue;
            }

            p {
              font-family: "Inter", sans-serif;
              font-style: normal;
              font-weight: normal;
              font-size: 16px;
              line-height: 24px;
              color: $table-header;
              margin-bottom: 0;
            }

            .custom-file-input {
              position: absolute;
              opacity: 0;
              cursor: pointer;
            }

            .custom-file-input::-webkit-file-upload-button {
              visibility: hidden;
            }
          }

          .bordered-div {
            border: 1px solid rgba(177, 188, 199, 0.6);
            border-radius: 4px;
            height: 50px;
            padding: 18px;
            width: 100%;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: space-between;

            &-icon {
              width: 22px;
              height: 22px;

              img {
                width: 100%;
                height: 100%;
              }
            }

            span {
              margin-left: 20px;
              margin-right: 4px;
              font-family: "Inter", sans-serif;
              font-style: normal;
              font-weight: normal;
              font-size: 16px;
              line-height: 24px;
              color: $light-blue;
            }
          }
        }
      }
    }

    &-right {
      &-btn,
      &-btn2 {
        margin-bottom: 28px;
        height: 48px;
        align-items: center;
      }

      &-btn {
        display: flex;
        justify-content: flex-end;
      }

      &-btn2 {
        display: none;
        justify-content: center;
      }
    }
  }
}

.hyiOnG,
.hoTIGx {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  max-width: 100% !important;
  width: inherit !important;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  border-radius: 5px;
  border: none !important;
  border-radius: 4px !important;
  height: 50px !important;
  padding: 0px !important;
  box-sizing: border-box !important;
  cursor: pointer;
}

.blog-editor {
  position: relative;
  height: 100%;
  width: inherit;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 4px 4px 0px 0px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: $light-dark-color;
  border: 1px solid $table-header;
  // border-bottom: none;
}

.demo-editor {
  position: relative;
  min-height: 329px !important;
  max-height: 500px !important;
  width: inherit !important;
  background: #ffffff !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;
  font-family: "Inter", sans-serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: $light-dark-color !important;
  overflow: auto !important;
  padding: 16px;
}

.rdw-embedded-modal {
  position: absolute !important;
  top: 35px !important;
  left: -175px !important;
  right: 5px !important;
  display: flex !important;
  flex-direction: column !important;
  width: 235px !important;
  height: 200px !important;
  border: 1px solid #f1f1f1 !important;
  padding: 15px !important;
  border-radius: 2px !important;
  z-index: 100 !important;
  background: white !important;
  justify-content: space-between;
  box-shadow: 3px 3px 5px #bfbdbd;
}

.rdw-link-modal {
  width: 235px !important;
  height: 250px !important;
}

.rdw-editor-toolbar {
  left: 0 !important;
  position: absolute !important;
  bottom: -50px !important;
  z-index: 100;
  width: 100% !important;
  justify-content: space-around !important;
  border: 1px solid $table-header !important;
  // border-top: none !important;
  margin-bottom: 0px !important;
  border-radius: 0px 0px 4px 4px !important;
  padding-bottom: 0px !important;
  box-sizing: border-box !important;
}

.public-DraftStyleDefault-block {
  margin: 0 !important;
}

.rdw-fontsize-dropdown {
  min-width: 60px !important;
}

.rdw-image-modal {
  position: absolute;
  top: 35px;
  left: 5px;
  display: flex;
  flex-direction: column;
  width: fit-content !important;
  border: 1px solid #f1f1f1;
  padding: 15px;
  border-radius: 2px;
  z-index: 100;
  background: white;
  box-shadow: 3px 3px 5px #bfbdbd;
}

@media screen and (max-width: 768px) {
  .blog {
    &-post {
      grid-template-columns: 1fr;

      &-left {
        &-title {
          width: 100%;
        }
      }
    }
  }

  .dashed-div {
    justify-content: center;
  }

  .dashed-div p {
    display: none;
  }

  .blog-post-right-btn {
    display: none;
  }

  .blog-post-right-btn2 {
    display: flex;
    margin-top: 32px;
  }
}
