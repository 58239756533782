.overview {
  width: 100%;
  height: 100%;

  &-header-right {
    display: flex;
    align-items: center;
    width: 100%;

    &-button {
      position: relative;
    }

    .mini {
      &__tab {
        padding: 6px 8px;
        background: #ffffff;
        border: 1px solid #e5e5e5;
        border-radius: 1px;
        color: #a4a7b7;
        transition: 0.3s all ease-out;
        cursor: pointer;

        &.active {
          background: #0a74dc;
          color: #fff;
        }

        &-wrapper {
          position: relative;
        }

        &-dropdown {
          position: absolute;
          min-width: 120%;
          background: #fff;
          box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1);
          left: 50%;
          top: 100%;
          transform: translateX(-50%);
          border-radius: 4px;
          padding: 4px 6px;
          pointer-events: none;
          opacity: 0;
          visibility: hidden;
          transition: 0.3s all ease-out;
          list-style-type: none;
          max-height: 200px;
          overflow-y: scroll;

          &-item {
            transition: 0.3s all ease-out;
            padding: 8px;

            &:hover {
              cursor: pointer;
              background: lighten(#0a74dc, 50%);
              border-radius: 4px;
              font-weight: bolder;
            }
          }

          &.active {
            opacity: 1;
            visibility: visible;
            pointer-events: auto;
          }
        }
      }

      &-icon {
        width: 40px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &-img {
          width: 17px;
          height: 3px;
        }
      }
    }
  }

  &-figures,
  &-figures2 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 28px;

    &-div {
      width: 100%;
      min-height: 96px;
      padding: 16px;
      box-sizing: border-box;
      border: 1px solid $table-border;
      box-sizing: border-box;
      border-radius: 5px;
      background: #ffffff;

      &-top {
        display: flex;
        justify-content: space-between;

        h2 {
          font-family: "Inter", sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 32px;
          color: $deep-dark-color;
          margin-bottom: 0;
        }

        span {
          font-family: "Inter", sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 24px;
        }
      }

      &-bottom {
        margin-top: 8px;
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 24px;
        letter-spacing: -0.03em;
        color: #818181;
        // text-transform: capitalize !important;
      }
    }
  }

  &-figures2 {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 28px;
  }

  &-figures3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 28px;

    &-div {
      width: 100%;
      min-height: 110px;
      padding: 16px;
      box-sizing: border-box;
      border: 1px solid $table-border;
      box-sizing: border-box;
      border-radius: 12px;
      background: #ffffff;
    }
  }

  &-graph {
    margin-top: 32px;
    width: 100%;
    // min-height: 556px;
    background: #ffffff;
    border: 1px solid $table-border;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 24px 32px;
    box-sizing: border-box;

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &-left {
        h4 {
          font-family: "Inter", sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 32px;
          color: $deep-dark-color;
          margin-bottom: 0;
        }

        p {
          margin-top: 8px;
          font-family: "Inter", sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 16px;
          color: #a4a7b7;
          margin-bottom: 0;
        }
      }

      &-right {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-left: 22px;

        &-indicators {
          display: flex;
          margin-right: 40px;
          

          span {
            margin-left: 12px;
            font-family: "Inter", sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 32px;
            display: flex;
            align-items: center;
            color: $deep-dark-color;
          }
        }
      }
    }

    &-section {
      margin-top: 40px;
    }
  }

  &-recents {
    width: 100%;
    display: grid;
    grid-template-columns: 1.5fr 2fr;
    gap: 32px;
    margin-top: 32px;

    h5 {
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 32px;
      color: $deep-dark-color;
    }

    &-chart,
    &-appointments {
      width: 100%;
      min-height: 554px;
      background: #ffffff;
      border: 1px solid $table-border;
      box-sizing: border-box;
      border-radius: 4px;
    }

    &-chart {
      padding: 24px 32px;

      .doughnut {
        position: relative;

        &__info {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          & h2 {
            font-weight: 600;
            font-size: 2.4rem;
            margin-bottom: 0;
          }
        }
      }

      .labels {
        display: flex;
        flex-flow: row wrap;
        gap: 24px 30px;

        &-grid {
          display: grid;

          .indicator {
            width: 16px;
            height: 16px;
            border-radius: 2px;
          }

          .texts {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 4px;

            h6,
            span {
              font-family: "Inter", sans-serif;
              font-style: normal;
              font-size: 16px;
              line-height: 24px;
            }

            h6 {
              font-weight: normal;
              color: $table-header;
              margin-bottom: 0;
              margin-right: 1rem;
            }

            span {
              font-weight: 500;
              color: #263238;
            }
          }
        }
      }
    }

    &-appointments {
      padding: 24px 32px;
    }
  }

  &-table {
    margin-top: 32px;
    width: 100%;
    min-height: 556px;
    background: #ffffff;
    border: 1px solid $table-border;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 28px 32px;
    box-sizing: border-box;

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h2 {
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        color: $deep-dark-color;
        margin-bottom: 0;
      }

      a {
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #0a74dc; 
        text-decoration-line: underline;
      }
    }
  }
}

.overview-header-right-button > .table-dropdown {
  right: 0;
}

@media (min-width: 768px) and (max-width: 1300px) {
  .overview-recents-chart .labels-grid .texts {
    display: grid;
  }
}

@media screen and (max-width: 768px) {
  .overview-header-right {
    display: grid;
    grid-gap: 24px;
    margin-top: 10px;
  }

  .overview-header-right .seasons {
    margin-left: 0px;
  }

  .overview-figures,
  .overview-figures2 {
    display: grid;
    grid-template-columns: 1fr;

    &-div {
      &-bottom {
        br {
          display: none;
        }
      }
    }
  }

  .overview-graph {
    padding: 20px;
  }

  .overview-graph-header {
    display: grid;
  }

  .overview-graph-header-right {
    margin-top: 15px;
  }

  .overview-recents,
  .overview-recents-chart .labels {
    grid-template-columns: 1fr;
  }

  .overview-graph {
    min-height: 0;
  }

  .overview-header-right-button > .table-dropdown {
    right: 0;
    left: 0;
  }
}


.chart-grid {
  display: flex;
  align-items: center;
   justify-content: space-between;
  &-left {
    flex: 0 0 49%;
    height: 60%;
    overflow: hidden;
  }
  &-right {
    flex: 0 0 49%;
  }
}