@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300;400;500;600&display=swap');

body {
  // font-family: 'Inter', sans-serif;
  font-family: 'IBM Plex Sans';
  font-size: 1.6rem;
}

html {
  font-size: 62.5%;
}

a {
  text-decoration: none;
}

.container {
  width: 100%;
  height: 100%;
  height: 100vh;
  box-sizing: border-box;
  background: #ffffff;
}

.container2 {
  width: 100%;
  height: 100%;
  height: 100vh;
  box-sizing: border-box;
  background: $dark-background;
}

.container3 {
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  grid-column-gap: 15px;
  width: 100%;
  height: 100vh;

  &-left {
    display: grid;
    align-content: center;
    justify-content: left;
  }

  &-right {
    display: flex;
    flex-direction: column;
    width: 215px;
    height: 9;
    max-height: 900px;
    box-sizing: border-box;
    justify-self: right;
    justify-content: center;
    background: #ffffff;
    border-radius: 8px;

    &-top {
      display: grid;
      background: #0a74dc;
      border: none;
      border-radius: 2px;
      height: 64px;
      width: 100%;
      grid-row-gap: 0px;
      align-content: center;
      font-family: 'Inter', sans-serif;
      font-style: normal;
      line-height: 24px;
      cursor: pointer;

      h4 {
        font-weight: 500;
        font-size: 16px;
        color: #ffffff;
        padding-left: 20px;
        margin-bottom: 0;
      }

      h6 {
        font-weight: normal;
        font-size: 12px;
        color: #ffffff;
        opacity: 0.6;
        padding-left: 20px;
        margin-bottom: 0;
      }
    }

    &-hr {
      width: 100%;
      height: 1px;
      background: rgba(164, 167, 183, 0.3);
      margin: 5px 0px;
    }

    &-bottom {
      display: grid;
      border: none;
      border-radius: 2px;
      height: 64px;
      grid-row-gap: 0px;
      align-content: center;
      font-family: 'Inter', sans-serif;
      font-style: normal;
      line-height: 24px;
      cursor: pointer;

      h4 {
        font-weight: 500;
        font-size: 16px;
        color: #979797;
        padding-left: 20px;
        margin-bottom: 0;
      }

      h6 {
        font-weight: normal;
        font-size: 12px;
        color: $table-header;
        padding-left: 20px;
        margin-bottom: 0;
      }
    }
  }
}

.container-body,
.container-body2 {
  // background: #ffffff;
  background: $gray-200;
  position: relative;
  top: 64px;
  margin-left: 272px;
  padding: 32px 40px;
  padding-bottom: 50px;
  box-sizing: border-box;
}

.container-body2 {
  padding: 0;
}

@media screen and (max-width: 768px) {
  .container-body {
    background: #ffffff;
    position: relative;
    top: 64px;
    margin-left: 0px;
    padding: 16px 20px;
    width: 100%;
  }

  .container-body2 {
    background: #ffffff;
    position: relative;
    top: 64px;
    margin-left: 0px;
    width: 100%;
  }
  .container3 {
    grid-auto-flow: row;
    &-right {
      justify-self: center;
      margin-top: 40px;
    }
  }
}
