.payment-plans {
  width: 100%;
  background: #fff;

  &-empty {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .payment-grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 37px;
  }

  &-section {
    min-height: 576px;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.4);
    border: 1px solid rgba(177, 188, 199, 0.4);
    box-sizing: border-box;
    border-radius: 4px;
    position: relative;
    cursor: pointer;

    &-type,
    &-price {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &-button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    &-type {
      width: 100%;
      height: 150px;
      background: rgba(177, 188, 199, 0.15);

      .popular-tag-section {
        display: flex;
        position: absolute;
        top: 0;
        width: 100%;

        .show-tag {
          width: 56px;
          height: 58px;
          position: relative;

          .popular-tag {
            width: 100%;
            height: 100%;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .popular-tag-text {
            font-family: "Inter";
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            text-align: center;
            letter-spacing: -0.01em;
            color: #fff;
            top: 5px;
            right: 0;
            left: 0;
            position: absolute;
            z-index: 100;
            overflow-wrap: break-word;
          }
        }
      }

      h2 {
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        color: $deep-dark-color;
        text-transform: uppercase;
        margin-bottom: 0;
      }

      h3 {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: #979797;
        margin-top: 12px;
        margin-bottom: 0;
      }
    }

    &-price {
      width: 100%;
      height: 129px;

      h2 {
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 40px;
        line-height: 32px;
        color: $deep-dark-color;
        margin-bottom: 0;

        span {
          font-size: 24px;
          line-height: 24px;
        }
      }

      h4 {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: $table-header;
        margin-top: 12px;
        margin-bottom: 0;
      }
    }

    &-benefits {
      display: flex;
      flex-direction: column;
      min-height: 200px;
      max-height: 200px;
      overflow: auto;

      &-div {
        height: 50px;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 13px 34px;
        padding-right: 12px;
        box-sizing: border-box;

        span {
          margin-left: 12px;
          font-family: "Inter", sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 20px;
          color: #0d0f15;
        }
      }

      &-div:nth-child(odd) {
        background: rgba(177, 188, 199, 0.1);
      }
    }

    &-button {
      height: 150px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      button {
        font-family: "Inter", sans-serif;
        box-sizing: border-box;
        padding: 0 29px;
        border: none;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.2px;
        border-radius: 4px;
        color: $light-blue;
        background: rgba(10, 116, 220, 0.08);
        border: 1px solid rgba(10, 116, 220, 0.6);
        height: 48px;
        width: 131px;
      }

      button:disabled {
        color: #fff;
        background: rgba(164, 167, 183, 0.4) !important;
        cursor: not-allowed;
      }
    }
  }

  &-section:hover {
    border: 1px solid rgba(10, 116, 220, 0.4);

    .payment-plans-section-type {
      background: $light-blue;

      h2 {
        color: #fff;
        margin-bottom: 0;
      }

      h3 {
        color: #fff;
        margin-bottom: 0;
      }
    }

    .payment-plans-section-button button {
      color: #ffffff;
      background-color: #0a74dc;
    }
  }
}

.payment-table {
  margin-top: 80px;

  &-header {
    display: flex;
    justify-content: space-between;

    h2 {
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      color: $deep-dark-color;
      margin-bottom: 0;
    }

    span {
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 32px;
      text-decoration-line: underline;
      color: $light-blue;
      cursor: pointer;
    }
  }
}

.payment-modal {
  margin-top: 56px;

  &-header {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: $table-header;
  }

  &-add-more {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: $light-blue;
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
    margin-bottom: 12px;

    span {
      width: fit-content;
      cursor: pointer;
    }
  }
}

.payment-btns-notactivated {
  width: 97px;
  height: 24px;
  background: rgba(13, 47, 161, 0.08);
  border: 1px solid $light-blue;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 24px;
  color: $light-blue;
}

.payment-btns-activated {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #00dcb4;
}

.assign-plan-btn {
  border: none;
  border-radius: 4px;
  width: 97px;
  height: 24px;
  margin-top: 3px;
  font-size: 13px;
  color: $light-blue;
  background: rgba(13, 47, 161, 0.08);
}

@media screen and (max-width: 768px) {
  .payment-plans .payment-grid {
    grid-template-columns: 1fr;
  }

  .payment-plans-section {
    padding-bottom: 20px;
  }
}
