@import "~antd/dist/antd.css";

.ant-notification {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  position: fixed;
  z-index: 1010;
  margin-right: 24px;
  right: 0px;
  top: 24px;
  bottom: auto;
}

.anticon.ant-notification-notice-icon-warning {
  color: #faad14 !important;
}

.anticon.ant-notification-notice-icon-error {
  color: #ee5547 !important;
}

.anticon.ant-notification-notice-icon-success {
  color: #00dcb4 !important;
}

.anticon.ant-notification-notice-icon-info {
  color: #1890ff !important;
}

.ant-notification-notice-success {
  border-left: 5px solid #00dcb4 !important;
}

.ant-notification-notice-error {
  border-left: 5px solid#EE5547 !important;
}

.ant-notification-notice-warning {
  border-left: 5px solid #faad14 !important;
}

.ant-notification-notice-info {
  border-left: 5px solid #1890ff !important;
}

.ant-notification-notice {
  position: relative;
  width: 384px;
  max-width: calc(100vw - 24px * 2);
  margin-bottom: 16px;
  margin-left: auto;
  padding: 13px 11px !important;
  overflow: hidden;
  line-height: 1.5715;
  word-wrap: break-word;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
}

.anticon {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ant-notification-close-icon {
  font-size: 14px;
  cursor: pointer;
}

.ant-notification-notice-icon {
  position: absolute;
  margin-left: 4px;
  font-size: 24px;
  line-height: 24px;
}

.ant-notification-notice-message {
  font-family: "Inter", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 130% !important;
  letter-spacing: -0.02em !important;
  color: #4f4f4f !important;
  margin-bottom: 8px !important;
}

.ant-notification-notice-description {
  font-family: "Inter", sans-serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 12px !important;
  line-height: 130% !important;
  letter-spacing: -0.01em !important;
  color: #a7a7a7 !important;
}

.ant-notification-notice-icon>svg {
  width: 20px !important;
  height: 20px !important;
}

.ant-notification-notice-with-icon .ant-notification-notice-message {
  margin-left: 37px !important;
}

.ant-notification-notice-with-icon .ant-notification-notice-description {
  margin-left: 37px !important;
}

.ant-notification-close-icon>svg {
  width: 12px !important;
  height: 12px !important;
}

.ant-notification-notice-close {
  position: absolute;
  top: 9px !important;
  right: 22px;
  float: right;
  bottom: 100%;
  touch-action: manipulation;
}

.ant-notification-notice-close:active>.ant-notification {
  display: none;
}

.carousel-container {
  width: 100%;
  display: flex;
}

.carousel-wrapper {
  display: flex;
  width: 100%;
  position: relative;
}

.carousel-content-wrapper {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.carousel-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  transition: all 250ms linear;
  -ms-overflow-style: none;
  /* hide scrollbar in IE and Edge */
  scrollbar-width: none;
  /* hide scrollbar in Firefox */
}

/* hide scrollbar in webkit browser */
.carousel-content::-webkit-scrollbar,
.carousel-content::-webkit-scrollbar {
  display: none;
}

.carousel-content>* {
  width: 100%;
  flex-shrink: 0;
  flex-grow: 1;
  margin-right: 10px;
}

.carousel-content.show-2>* {
  width: 50%;
}

.carousel-content.show-3>* {
  width: calc(100% / 3);
}

.carousel-content.show-4>* {
  width: calc(96.5% / 4);
}


.carousel-content.show-5>* {
  width: calc(100% / 5);
}

.carousel-btns {
  display: flex;
  align-items: center;
}

.left-arrow,
.right-arrow {
  z-index: 1;
  width: 20px;
  height: 20px;
  border-radius: 24px;
  background-color: transparent;
  color: #6d7a98;
  border: none;
  font-size: 20px;
  font-weight: 600;
  font-family: "Inter", sans-serif;
}

.left-arrow:disabled,
.right-arrow:disabled {
  background-color: transparent;
  color: rgba(177, 188, 199, 0.4);
  font-size: 20px;
  font-weight: 600;
  font-family: "Inter", sans-serif;
  cursor: not-allowed;
}

/* Danny - Danny - Danny - Danny - Admin Referral Codes */
.tab-container {
  border-radius: 5px;
  display: flex;
  width: 60%;
  margin-bottom: 20px;
}

.tab-container .tab-item {
  width: 16%;
}


.tab-container .tab-item:hover {
  cursor: pointer;
}

.tab-container .tab-item h2 {
  font-size: 1.7rem;
  /* color: #a7a7a7; */
  color: #A4A7BD;
  text-align: left;
  padding-left: 15px;
}

.tab-container .tab-item.active {
  border-bottom: 2px solid #0A74E2;
}

.tab-container .tab-item.active h2 {
  color: #0A74E2;
}

.table-header h2 {
  font-size: 3rem;
  font-weight: 700;
}

.table-header p {
  font-size: 1.6rem;
  /* font-weight: 600; */
  color: #A4A7BD;
}

.table-header h2.table-header-title {
  color: #434854;
}

.table-header .table-header-subtitle {
  color: #a7a7a7;
  margin-top: -15px;
  margin-bottom: 30px;
}

.table-section {
  overflow-x: scroll;
}

#referral-code .filters-section {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: -74px;
}

#referral-code .filters-section .filter-left {
  position: relative;
}

#referral-code .filters-section .filter-left .searchIcon {
  position: absolute;
  top: 18px;
  left: 15px;
  font-size: 20px;
  color: #A4A7BD;
}

#referral-code .filters-section input[type="text"] {
  width: 300px;
  height: 40px;
  border: 1px solid #A4A7BD;
  border-radius: 5px;
  margin-top: 12px;
  margin-left: 5px;
  padding-left: 35px;
}

#referral-code .filters-section input[type="text"]::placeholder {
  /* font-weight: bold; */
  color: #A4A7BD;
}

#referral-code .filters-section input[type="date"] {
  border: none;
}

#referral-code .filters-section input[type="date"]:first-child {
  margin: 0 10px;
}

::-webkit-datetime-edit {
  padding: 1em;
  font-size: 14px;
  color: #A4A7BD;
}

::-webkit-calendar-picker-indicator {
  margin-left: -30px;
  margin-top: 4px;
  color: #A4A7BD;
}

::-webkit-calendar-picker-indicator:hover {
  cursor: pointer;
}

#referral-code .filters-section .filter-right {
  display: inline-block;
  padding: 5px;
}

#referral-code table {
  border: 1px solid whitesmoke;
  width: 100%;
  margin-top: 20px;
}

#referral-code table.table-border {
  border-radius: 5px;
}

#referral-code table thead {
  padding: 10px;
}

#referral-code table th {
  color: #A4A7BD;
  font-size: 17px !important;
}

#referral-code table tr th {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

#referral-code table tr {
  border-bottom: 1px solid lightgrey;
}

#referral-code table th,
#referral-code table tr,
#referral-code table td {
  font-size: 16px;
  text-align: left;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 40px;
}

#referral-code table tr:hover {
  box-shadow: 2px 2px 8px 8px whitesmoke;
  cursor: pointer;
}

#referral-code button {
  font-weight: 600;
  border: none;
  padding: 8px 15px;
  border-radius: 7px;
  color: #0A74E2;
  background-color: #EBF4FD;
  border: 1px solid #0A74E2;
}

#referral-code button:hover {
  cursor: pointer;
  background-color: #0A74E2;
  color: white;
}

.displayFlex {
  display: flex;
  margin-bottom: 20px;
}

.displayFlex .backText {
  padding-left: 8px;
  font-size: 1.85rem;
  color: #A4A7BD;
}

.displayFlex .backIcon {
  /* padding-left: 8px; */
  padding-top: 7px;
  color: #A4A7BD;
}

.backSection:hover {
  cursor: pointer;
}

/* Pagination */
.pagination {
  /* border: 1px solid #a7a7a7; */
  display: flex;
  margin: 15px auto;
}

.page-item {
  border: 1px solid #a7a7a7;
  padding: 5px 10px;
}

.page-item:hover {
  cursor: pointer;
}

.page-item:first-child {
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
}

.page-item:last-child {
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}

.page-link.paginateTextBlack {
  color: #4f4f4f;
}

.page-link {
  color: #4f4f4f;
}

.page-link:hover {
  cursor: pointer;
}

.activePage,
.paginationBg {
  color: white !important;
  background-color: #4f4f4f;
}

/*  Danny - Danny - Danny - Danny - Chat Page  */
button.new-chat-button {
  border: 1px solid #0a74dc;
  border-radius: 5px;
  color: #0a74dc;
  padding: 3px 15px;
  font-weight: 600;
  font-size: 18px;
  margin-right: -32px;
  background-color: #f6fafd;
  display: flex;
}

button.new-chat-button:hover {
  cursor: alias;
}

.new-chat-icon {
  margin-top: 2px !important;
  margin-left: 5px;
}

.rdrDateRangePickerWrapper.date-range-picker-chat {
  position: relative;
  z-index: 1;
}

.date-range-picker-chat-button {
  z-index: 1;
}

/*  Danny - Danny - Danny - Danny - User Page  */
.edit-flex {
  width: 400px;
  display: flex;
  justify-content: space-between;
}

.edit-flex-2 {
  width: 480px;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.edit-flex-2 textarea {
  width: 350px;
  border: 1px solid lightgray;
  border-radius: 4px;
  color: darkgray;
}

.edit-flex-2 input {
  color: darkgray;
  font-size: 16px;
}

.edit-flex-2 .header {
  font-size: 18px;
}

.editingMode input {
  border-right: none;
  border-left: none;
  border-top: none;
  border-bottom: 2px solid lightgray;
  font-size: 18px;
}

.editingMode select {
  border-right: none;
  border-left: none;
  border-top: none;
  border-bottom: 2px solid lightgray;
  font-size: 18px;
  width: 222px;
}

.edit-flex .suspended {
  color: #00dcb4;
}


/* Danny - Danny - Danny - Payment plan page  */
#subscribersDateFilter input {
  border: 1px solid darkgray;
  border-radius: 5px;
  padding: 6px 12px;
}

.subscribersDateBox {
  position: absolute;
  top: -8%;
  padding-left: 91%;
}

.subscriberSelectFlex {
  display: flex;
  justify-content: space-between;
}

.subscriberSelect {
  border: 1px solid lightgray;
  border-radius: 6px;
}

select.subscriberSelect option:hover {
  box-shadow: 0 0 10px 100px #FAFAFA inset;
  cursor: pointer;
}


/* Media Queries - Media Queries - Media Queries */
@media screen and (max-width: 1450px) {
  .tab-container .tab-item {
    width: 23%;
  }

  #referral-code .filters-section {
    display: flex;
    justify-content: flex-end;
    width: 70%;
    margin-top: -74px;
    margin-left: auto;
  }
}

@media screen and (max-width: 1030px) {
  .tab-container {
    width: 70%;
  }

  .tab-container .tab-item {
    width: 25%;
  }

  #referral-code .filters-section {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 0;
    margin-left: 0;
  }
}

@media screen and (max-width: 770px) {
  .carousel-container {
    width: 100%;
    display: grid;
  }

  .carousel-content>* {
    /* width: 100%; */
    /* flex-shrink: 0; */
    flex-grow: 1;
    margin-right: 10px;
  }

  .carousel-btns {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .subscribersDateBox {
    top: -30%;
    padding-left: 91%;
  }
}


@media screen and (max-width: 430px) {
  #referral-code .filters-section {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .tab-container {
    width: 100%;
  }

  .tab-container .tab-item {
    width: 24%;
  }

  .tab-container .tab-item h2 {
    font-size: 1.5rem;
  }

  .table-section {
    overflow-x: scroll;
  }

  #referral-code .filters-section input[type="text"] {
    width: 97%;
  }

  .filter-right div {
    margin-left: 30px;
  }

  #referral-code .filters-section input[type="date"]:first-child {
    margin-right: 10px;
  }
}