.table-action-button {
  background-color: #0a73dc2b;
  display: inline-block;
  text-align: center;
  color: #0866c4;
  padding: .6rem 1.5rem;
  border-radius: 1rem;
  border: 1px solid #0866c4;
  
}

.table-wrapper {
  width: 100%;
  min-height: 40vh;
  border-radius: 5px;
  // overflow: hidden;
  padding: 0 20px;
  border: 1px solid rgba(164, 167, 183, 0.4);
  margin-top: 16px;
  background: #fff;
}

.table {
  width: 100%;
  height: 100%;
  text-align: center;
  font-family: "Inter", sans-serif;
  // background: #ffffff;
  box-sizing: border-box;
  padding: 24px 19px 24px 32px;

  thead {
    padding: .2rem 1rem;
    line-height: 2px;
    width: 100%;
    border-bottom: 3px solid $table-border;
  }

  thead > tr,
  tbody > tr {
    width: 100%;
    display: flex;
    align-items: center;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    line-height: 24px;
    text-align: left;
    transition: all 200ms ease-in;
    padding: 2px 19px 2px 32px;
    box-sizing: border-box;
    border-bottom: 1px solid $table-border;
    // background: #fff;
    border-radius: 4px;
  }

  thead > tr {
    font-weight: 500;
    color: $table-header;
    padding: 2rem 0;
    // height: 71px;
  }
  
  tbody > tr {
    padding: 2rem 0;
    // height: 74px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: $deep-dark-color;
    width: 100%;
    box-sizing: border-box;
    cursor: pointer;
  }

  tbody > tr:hover {
    // border-bottom: none;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(13, 47, 161, 0.07);
  }

  td,
  th {
    width: inherit;
    text-align: left;
    padding: 0;
    overflow-wrap: initial;
  }
  .table-price {
    width: 100%;
    display: flex;
    align-items: center;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    transition: all 200ms ease-in;
    padding: 24px 19px 24px 32px;
    box-sizing: border-box;
    height: 74px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: $deep-dark-color;
    width: 100%;
    box-sizing: border-box;
    cursor: pointer;
    border-bottom: 1px solid #e5e5e5;
  }

  .table-email {
    width: 85%;
    word-break: break-all;
    text-transform: lowercase;
  }

  .table-shrink {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .table-flex {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;

    &-image {
      width: 42px;
      height: 42px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }

    span {
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 24px;
      color: $deep-dark-color;
    }

    &-date {
      small {
        color: #81909d;
        font-size: 13px;
      }
    }
  }

  .drop-btn {
    position: relative;
    width: 24px;
    height: 24px;
    background: #ffffff;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &-img {
      width: 2px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.table-empty2 {
  width: 100%;
  height: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;

  &-container {
    max-width: 403px;
    height: 160px;

    &-image {
      width: 84px;
      height: 96px;
      margin: 0 auto;

      img {
        width: 100%;
        height: 100%;
      }
    }

    h2 {
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0.2px;
      color: #2f2e80;
      margin-top: 16px;
      margin-bottom: 0;
    }
  }
}

.table-empty {
  width: 100%;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;

  &-container {
    max-width: 403px;
    height: 160px;

    &-image {
      width: 84px;
      height: 96px;
      margin: 0 auto;

      img {
        width: 100%;
        height: 100%;
      }
    }

    &-image2 {
      width: 200x;
      height: 180px;
      margin: 0 auto;

      img {
        width: 100%;
        height: 100%;
      }
    }

    h2 {
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0.2px;
      color: #303b54;
      margin-top: 16px;
      margin-bottom: 0;
    }

    h3 {
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 16px;
      text-align: center;
      letter-spacing: -0.05px;
      color: $light-dark-color;
      margin-top: 8px;
      margin-bottom: 0;
    }
  }
}

.code-link {
  min-width: 90px;
  max-width: fit-content;
  height: 24px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 24px;
  letter-spacing: -0.02em;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 8px;
  background: #eff2f4;
}

.success-btn,
.error-btn,
.pending-btn,
.info-btn {
  min-width: 90px;
  max-width: fit-content;
  height: 24px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 24px;
  letter-spacing: -0.02em;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  box-sizing: border-box;
  padding: 8px;

  span {
    text-transform: capitalize;
  }
}

.success-btn,
.error-btn,
.pending-btn,
.info-btn {
  &-image {
    background: #fff;
  }
}

.success-btn {
  background: #eafbf1;
  color: $success-color;
}

.error-btn {
  background: $error-color-dull;
  color: $error-color;
}

.pending-btn {
  background: $info-color-dull;
  color: $info-color;
}

.info-btn {
  background: rgba(10, 116, 220, 0.1);
  color: $light-blue;
}

.action-flex {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;

  &.center {
    justify-content: center;
  }

  &-image {
    width: 32px;

    img {
      width: 100%;
      height: 100%;
      filter: grayscale(60%);
      opacity: 0.5;
    }
  }
}

tbody > tr:hover {
  .action-flex-image img {
    filter: grayscale(0%);
    opacity: 1;
  }
}

.action-state1,
.action-state2 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
}

.action-state1 {
  color: $success-color;
}

.action-state2 {
  color: $error-color;
}

.table-dropdown {
  transform: translateY(2%);
  transition-delay: 0s, 0s, 0.3s;
  position: absolute;
  z-index: 10;
  top: 100%;
  right: 5px;
  min-height: 40px;
  width: 144px;
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 0px 12px rgba(164, 167, 183, 0.2);
  border-radius: 2px;
  padding: 9px 10px;

  &-item {
    height: 40px;
    width: 100%;
    padding: 4px 16px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;

    &-image {
      img {
        opacity: 0.7;
      }
    }

    &-text {
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      color: $light-dark-color;
    }

    &:hover {
      background: rgba(13, 47, 161, 0.08);

      &-image {
        img {
          opacity: 1;
          filter: grayscale(100%);
        }
      }
    }

    &:hover img {
      opacity: 1;
      filter: grayscale(100%);
    }
  }
}

.table1 td:first-child,
.table1 th:first-child,
.table3 td:first-child,
.table3 th:first-child,
.table6 th:nth-child(2),
.table6 td:nth-child(2),
.table8 th:nth-child(2),
.table8 td:nth-child(2) {
  width: 200%;
  height: 100%;
  padding-left: 0px !important;
  box-sizing: border-box;
  align-items: center;
  display: flex;
}

.table4 th:nth-child(2),
.table4 td:nth-child(2) {
  width: 250%;
  height: 100%;
  padding-left: 0px !important;
  box-sizing: border-box;
  align-items: center;
  display: flex;
}

.table5 th:nth-child(3),
.table5 td:nth-child(3) {
  width: 150%;
  height: 100%;
  padding-left: 0px !important;
  box-sizing: border-box;
  align-items: center;
  display: flex;
}

.table5 th:nth-child(4),
.table5 td:nth-child(4),
.table5 th:nth-child(5),
.table5 td:nth-child(5) {
  width: 60%;
  height: 100%;
  padding-left: 0px !important;
  box-sizing: border-box;
  align-items: center;
  display: flex;
}

.table1 td:nth-child(2),
.table1 th:nth-child(2),
.table3 td:first-child,
.table3 th:first-child {
  width: 120%;
  height: 100%;
  padding-left: 0px !important;
  box-sizing: border-box;
  align-items: center;
  display: flex;
}

.table7 th:nth-child(5),
.table7 td:nth-child(5) {
  width: 50%;
  height: 100%;
  padding-left: 0px !important;
  box-sizing: border-box;
  align-items: center;
  display: flex;
}

.table7 th:nth-child(6),
.table7 td:nth-child(6),
.table7 th:last-child,
.table7 td:last-child {
  width: 80%;
  height: 100%;
  padding-left: 0px !important;
  box-sizing: border-box;
  align-items: center;
  display: flex;
}

.table1 td:nth-child(3),
.table1 th:nth-child(3),
.table6 td:first-child,
.table6 th:first-child,
.table8 td:first-child,
.table8 th:first-child,
.table8 th:nth-child(3),
.table8 td:nth-child(3) {
  width: 120%;
  height: 100%;
  padding-left: 0px !important;
  box-sizing: border-box;
  align-items: center;
  display: flex;
}

.table8 td:last-child,
.table8 th:last-child {
  width: 60%;
  height: 100%;
  padding-left: 0px !important;
  box-sizing: border-box;
  align-items: center;
  display: flex;
}

.table1 td:nth-child(4),
.table1 th:nth-child(4),
.table1 td:nth-child(5),
.table1 th:nth-child(5),
.table3 td:nth-child(4),
.table3 th:nth-child(4),
.table3 td:nth-child(5),
.table3 th:nth-child(5) {
  width: 80%;
  height: 100%;
  padding-left: 0px !important;
  box-sizing: border-box;
  align-items: center;
  display: flex;
}

.table1 td:last-child,
.table1 th:last-child {
  width: 130%;
  height: 100%;
  padding-left: 0px !important;
  box-sizing: border-box;
  align-items: center;
  display: flex;
}

.table3 td:last-child,
.table3 th:last-child,
.table4 td:last-child,
.table4 th:last-child,
.table5 td:last-child,
.table5 th:last-child {
  width: 30%;
  height: 100%;
}

.manager-table td:first-child,
.manager-table th:first-child,
.manager-table td:nth-child(2),
.manager-table th:nth-child(2) {
  width: 70%;
  height: 100%;
  padding-left: 0px !important;
  box-sizing: border-box;
  align-items: center;
  display: flex;
}

.manager-table td:nth-child(3),
.manager-table th:nth-child(3) {
  width: 200%;
  height: 100%;
  padding-left: 0px !important;
  box-sizing: border-box;
  align-items: center;
  display: flex;
}

.table2 td:first-child,
.table2 th:first-child,
.main-order-table td:first-child,
.main-order-table th:first-child {
  width: 30%;
  height: 100%;
  padding-left: 0px !important;
  box-sizing: border-box;
  align-items: center;
  display: flex;
}

.assess-table td:first-child,
.assess-table th:first-child {
  width: 40%;
  height: 100%;
  padding-left: 0px !important;
  box-sizing: border-box;
  align-items: center;
  display: flex;
}

.main-order-table td:nth-child(3),
.main-order-table th:nth-child(3),
.assess-table td:nth-child(2),
.assess-table th:nth-child(2) {
  width: 200%;
  height: 100%;
  padding-left: 0px !important;
  box-sizing: border-box;
  align-items: center;
  display: flex;
}

.manager-table2 td:first-child,
.manager-table2 th:first-child,
.manager-table3 td:first-child,
.manager-table3 th:first-child,
.manager-table3 td:nth-child(2),
.manager-table3 th:nth-child(2),
.assess-table td:nth-child(3),
.assess-table th:nth-child(3) {
  width: 150%;
  height: 100%;
  padding-left: 0px !important;
  box-sizing: border-box;
  align-items: center;
  display: flex;
}

.manager-table2 td:nth-child(2),
.manager-table2 th:nth-child(2),
.manager-table3 td:nth-child(3),
.manager-table3 th:nth-child(3) {
  width: 300%;
  height: 100%;
  padding-left: 0px !important;
  box-sizing: border-box;
  align-items: center;
  display: flex;
}

.manager-table2 td:nth-child(3),
.manager-table2 th:nth-child(3) {
  width: 200%;
  height: 100%;
  padding-left: 0px !important;
  box-sizing: border-box;
  align-items: center;
  display: flex;
}

.manager-table2 td:nth-child(4),
.manager-table2 th:nth-child(4),
.assess-table td:nth-child(4),
.assess-table th:nth-child(4),
.assess-table td:nth-child(5),
.assess-table th:nth-child(5) {
  width: 60%;
  height: 100%;
  padding-left: 0px !important;
  box-sizing: border-box;
  align-items: center;
  display: flex;
}

.table5 th:nth-child(4),
.table5 td:nth-child(4),
.table5 th:nth-child(5),
.table5 td:nth-child(5) {
  width: 60%;
  height: 100%;
  padding-left: 0px !important;
  box-sizing: border-box;
  align-items: center;
  display: flex;
}

.table-green,
.table-blue {
  min-width: 65px;
  height: 28px;
  border-radius: 8px;
  padding: 8px;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-green {
  color: #fff;
}

.table-blue {
  background: #e3f2ff;
  color: #0a74dc;
  cursor: pointer;
}

.table-pagination {
  margin-top: 25px;
  width: 100%;
  height: 40px;
  padding: 0 16px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;

  &-left {
    display: flex;
    align-items: center;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: $deep-dark-color;
  }

  &-right {
    display: flex;
    align-items: center;

    .normalPage,
    .activePage {
      min-width: 23px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-right: 8px;
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: $deep-dark-color;
    }

    .activePage {
      background: $light-blue;
      // border-radius: 50%;
      padding: 1.6rem 1rem;
      border-radius: 45%;
      color: #ffffff;
    }

    button {
      border: none;
      background: none;
      margin-right: 4px;
    }

    button:disabled {
      color: $table-border;
      cursor: not-allowed;
    }
  }
}

.table-select-box {
  width: 14px;
  margin: 16px auto;
  position: relative;

  input[type="checkbox"] {
    width: 14px;
    height: 14px;
    margin-right: 13px;
    cursor: pointer;
    visibility: hidden;
  }

  input[type="checkbox"]:after {
    content: " ";
    display: inline-block;
    color: #fff;
    width: 14px;
    height: 14px;
    visibility: visible;
    background: #fff;
    border: 1px solid #c4c4c4;
    box-sizing: border-box;
    border-radius: 1px;
  }

  input[type="checkbox"]:checked:after {
    content: url("../../assets/checked.svg");
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 768px) {
  .table {
    line-height: 30px;

    thead {
      display: none;
    }

    tbody > tr {
      display: grid;
      grid-gap: 15px;
      box-sizing: border-box;
      background: #fff;
      border-radius: 4px;
      padding: 20px;
      width: 100%;
      height: fit-content;
      margin-bottom: 20px;
    }

    td {
      display: flex;
      justify-content: space-between;
      text-align: left;
      width: 100%;
      overflow-wrap: break-word;
      text-transform: capitalize;
    }
  }

  .table1 td:first-child,
  .table1 th:first-child {
    width: 100%;
    height: 100%;
    padding-left: 0px !important;
    box-sizing: border-box;
    align-items: center;
    display: flex;
  }

  .table1 td:last-child,
  .table1 th:last-child,
  .table2 td:first-child,
  .table2 th:first-child,
  .main-order-table td:first-child,
  .main-order-table th:first-child,
  .main-order-table td:nth-child(3),
  .main-order-table th:nth-child(3),
  .table3 td:first-child,
  .table3 th:first-child,
  .table4 th:nth-child(2),
  .table4 td:nth-child(2),
  .table5 th:nth-child(3),
  .table5 td:nth-child(3),
  .table5 th:nth-child(4),
  .table5 td:nth-child(4),
  .table5 th:nth-child(5),
  .table5 td:nth-child(5),
  .table6 td:first-child,
  .table6 th:first-child,
  .table6 th:nth-child(2),
  .table6 td:nth-child(2),
  .table8 td:first-child,
  .table8 th:first-child,
  .table8 th:nth-child(2),
  .table8 td:nth-child(2),
  .table8 th:nth-child(3),
  .table8 td:nth-child(3),
  .table8 th:last-child,
  .table8 td:last-child {
    width: 100%;
  }

  .table1 td:nth-child(2),
  .table1 th:nth-child(2),
  .table3 td:first-child,
  .table3 th:first-child,
  .table7 th:nth-child(5),
  .table7 td:nth-child(5),
  .table7 th:nth-child(6),
  .table7 td:nth-child(6),
  .table7 th:last-child,
  .table7 td:last-child,
  .manager-table2 td:first-child,
  .manager-table2 th:first-child,
  .manager-table2 td:nth-child(2),
  .manager-table2 th:nth-child(2),
  .manager-table2 td:nth-child(3),
  .manager-table2 th:nth-child(3),
  .manager-table2 td:nth-child(4),
  .manager-table2 th:nth-child(4),
  .manager-table td:first-child,
  .manager-table th:first-child,
  .manager-table td:nth-child(2),
  .manager-table th:nth-child(2),
  .manager-table td:nth-child(3),
  .manager-table th:nth-child(3),
  .assess-table td:first-child,
  .assess-table th:first-child,
  .assess-table td:nth-child(2),
  .assess-table th:nth-child(2),
  .assess-table td:nth-child(3),
  .assess-table th:nth-child(3),
  .assess-table td:nth-child(4),
  .assess-table th:nth-child(4),
  .assess-table td:nth-child(5),
  .assess-table th:nth-child(5),
  .main-order-table td:first-child,
  .main-order-table th:first-child,
  .main-order-table td:nth-child(3),
  .main-order-table th:nth-child(3),
  .manager-table3 td:first-child,
  .manager-table3 th:first-child,
  .manager-table3 td:nth-child(2),
  .manager-table3 th:nth-child(2),
  .manager-table3 td:nth-child(3),
  .manager-table3 th:nth-child(3) {
    width: 100% !important;
  }

  .table1 td:nth-child(3),
  .table1 th:nth-child(3),
  .table6 td:first-child,
  .table6 th:first-child {
    width: 100%;
  }

  .table-pagination {
    display: block;
    margin-bottom: 30px;

    &-left {
      margin-bottom: 15px;
    }
  }

  .table-dropdown {
    right: auto;
  }
}

.move-dropdownBtn img {
  margin-left: -4rem;
}