.coupon-details {
  h1 {
    font-weight: 700;
    font-size: 3.7rem;
    margin-bottom: 6rem;
  }
  .card {
    width: 100%;
    padding: 3rem 2.5rem;
    background: rgb(231, 230, 230);
    .content {
      background-color: white;
      display: flex;
      padding: 3rem 5rem;

      .left {
        flex: 0 0 60%;

        .info {
          display: flex;
          // align-items: center;
          h2 {
            margin-right: 1rem;
            font-size: 1.3rem;
            font-weight: 700;
          }
          p {
            font-size: 1.3rem;
            color: rgb(74, 74, 74);
            font-weight: 500;
          }
        }
      }
    }
    .right {
      flex: 0 0 40%;
      h3 {
        font-weight: 700;
      }
      p {
        font-size: 1.5rem;
        font-weight: 5400;
      }
    }
  }
}
