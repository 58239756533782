.assessment-manager {
  display: grid;
  grid-template-columns: 0.4fr 2fr;
  grid-gap: 100px;
  margin-top: 40px;

  &-left {
    &-header {
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #a4a7b7;
      margin-bottom: 32px;
    }

    &-body {
      width: 100%;

      .category-section {
        width: 100%;
        margin-bottom: 32px;
        min-height: 20px;
        box-sizing: border-box;

        &-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;

          span {
            font-family: "Inter", sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: $deep-dark-color;
          }

          img {
            cursor: pointer;
          }
        }

        &-body {
          margin-top: 10px;

          &-dropdown,
          &-dropdown2 {
            width: 100%;
            display: flex;
            align-items: center;
            border-radius: 4px;
            box-sizing: border-box;
            padding: 14px 20px;
            font-family: "Inter", sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            cursor: pointer;
          }

          &-dropdown {
            color: #a4a7b7;
            background: #fff;
          }

          &-dropdown2 {
            height: 52px;
            background: #0a74dc;
            color: #fff;
          }
        }
      }
    }
  }
}

.appointment-subcategories {
  margin-top: 48px;

  &-header {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: $deep-dark-color;
  }

  &-container > * {
    margin-top: 32px;
  }

  &-grid {
    border-bottom: 1px solid #e5e5e5;
    width: 100%;
    height: fit-content;
    padding-bottom: 24px;
    box-sizing: border-box;
    position: relative;

    &-layout {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 24px;
    }

    &-delete {
      position: absolute;
      display: flex;
      font-size: 16px;
      right: -30px;
      top: -25px;
      cursor: pointer;
      width: 25px;
      height: 25px;
      align-items: center;
      justify-content: center;
      background: rgba(177, 188, 199, 0.3);
      color: #b0b9c8;
      border-radius: 50%;
      float: right;
    }
  }

  &-container > *:first-child {
    margin-top: 0px;
  }

  &-image,
  &-image2 {
    width: 100%;
    height: 48px;
    background: rgba(229, 229, 229, 0.3);
    border: 1px dashed #e5e5e5;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &-div {
      width: 20px;
      height: 20px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    &-text {
      margin-left: 12px;
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: -0.01em;
      text-decoration-line: underline;
      color: #a4a7b7;
      cursor: pointer;
    }

    &-text2 {
      margin-left: 12px;
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: -0.01em;
      color: #a4a7b7;
    }
  }

  &-image2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 0 10px;
    box-sizing: border-box;

    &-div {
      width: 80%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .custom-file-input {
    position: absolute;
    opacity: 0;
    left: 70px;
    cursor: pointer;
    width: 100%;
    text-align: center;
  }

  .custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
  }
}

.assessment-manager-single {
  &-container {
    background: #ffffff;
    border: 1px solid rgba(164, 167, 183, 0.4);
    box-sizing: border-box;
    border-radius: 4px;
    width: 100%;
    height: 100%;
    padding: 43px 32px;
    box-sizing: border-box;
  }
}

.grid-4-one {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 24px;
  margin-top: 30px;
}
.grid-2-one {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 24px;
  margin-top: 30px;
}

@media screen and (max-width: 768px) {
  .appointment-subcategories {
    &-grid {
      grid-template-columns: 1fr;
    }
  }

  .grid-4-one {
    grid-template-columns: 1fr;
  }
}
