.assessment {
  &__card {
    background: #ffffff;
    border: 1px solid rgba(164, 167, 183, 0.4);
    border-radius: 4px;
    padding: 3.2rem;
    min-height: calc(100vh - 304px);
    position: relative;

    & > :first-child {
      margin-bottom: 33px;
    }

    &:after {
      position: absolute;
      content: "";
      top: 0;
      width: 100%;
      border-radius: 4px;
      left: 0;
      height: 100%;
      background: transparent;
      pointer-events: none;
      z-index: 20;
      transition: 0.5s all ease-out;
    }

    &.disabled:after {
      background: rgba(255, 255, 255, 0.5);
      backdrop-filter: blur(0.5px);
      pointer-events: auto;
    }
  }

  &__table {
    &-wrapper {
      border-collapse: collapse;
      width: 100%;
      text-align: left;
    }

    &-head {
      background: rgba(164, 167, 183, 0.2);
    }

    &-titles {
      padding: 1.6rem;
      font-size: 1.6rem;
      color: $light-dark-color;
    }

    &-data {
      padding-top: 2.4rem;
      padding-right: 2.4rem;

      &.pt-lg {
        padding-top: 3.2rem;
      }
    }
  }

  &__action-btn {
    background: none;
    outline: none;
    border: none;

    &:disabled {
      background: transparent;
      color: lighten(#0a74dc, 40%);
    }
    &.text-red:disabled {
      color: lighten(#dc0000, 40%);
    }
  }

  &__submit-btn {
    margin-top: 2rem;
    background: #0a74dc;
    border: none;
    border-radius: 4px;
    color: #fff;
    padding: 5px 20px;

    &:disabled {
      cursor: not-allowed;
      background: rgb(164, 164, 164) !important;
    }
  }
}
