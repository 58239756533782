$dark-background: #F9F9F9;
$deep-dark-color: #434854;
$light-dark-color: #737373;
$header-light: #B2B2B2;
$table-border: #E5E5E5;
$table-header: #000;
$success-color: #00DCB4;
$success-color-dull: rgba(0, 220, 180, 0.1);
$error-color: #DC0000;
$error-color-dull: rgba(220, 0, 0, 0.1);
$info-color: #FF9900;
$info-color-dull: rgba(255, 153, 0, 0.1);
$dull-text: #B0B5CA;
$border-color: rgba(177, 188, 199, 0.4);
$light-blue: #0A74DC;
$deep-blue: #031434;
$label-color: #B0B9C8;
$deep-grey: #a7a7a7;

$gray-100: #f8f9fa;
$gray-200: #f6f6f7;
$gray-300: #e2e7f1;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #4B4B5A;
$gray-800: #343a40;
$gray-900: #212529;
