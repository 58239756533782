.auth-table {
  display: grid;
  width: 100%;
  height: 100%;
  text-align: center;
  font-family: 'Inter', sans-serif;
  background: #ffffff;
  box-sizing: border-box;

  thead {
    width: 100%;
    border-radius: 2px;
  }

  thead > tr,
  tbody > tr {
    width: 100%;
    display: flex;
    align-items: center;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    transition: all 200ms ease-in;
    padding: 24px 19px 24px 10px;
    box-sizing: border-box;
    border-bottom: 1px solid $table-border;
  }

  thead > tr {
    font-weight: 500;
    color: $table-header;
    height: 71px;
  }

  tbody > tr {
    height: 74px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: $deep-dark-color;
    width: 100%;
    box-sizing: border-box;
    cursor: pointer;
  }

  tbody > tr:hover {
    // border-bottom: none;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(13, 47, 161, 0.07);
  }

  tfoot {
    text-align: -webkit-center;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: $deep-dark-color;
    width: 100%;
    box-sizing: border-box;
    cursor: pointer;
    border-bottom: 1px solid $table-border;
  }

  .total-price {
    display: flex;
    align-items: center;
    height: 74px;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: $deep-dark-color;

    span {
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 16px;
      line-height: 24px;
      color: $deep-dark-color;
    }
  }

  td,
  th {
    width: inherit;
    text-align: left;
    padding: 0;
    overflow-wrap: initial;
  }

  &-btn {
    display: flex;
    justify-content: center;
  }

  .table-flex {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;

    &-image {
      width: 42px;
      height: 42px;
      border-radius: 50%;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }

    span, h2 {
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: $deep-dark-color;

      h2 {
        margin-bottom: 0;
        margin-top: 20px;
      }

      p {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        color: #979797;
      }
    }
  }

  .table-empty {
    width: 100%;
    height: 25vh;
    display: flex;
    align-items: center;
    justify-content: center;

    &-container {
      max-width: 403px;
      height: 160px;

      &-image {
        width: 84px;
        height: 96px;
        margin: 0 auto;

        img {
          width: 100%;
          height: 100%;
        }
      }

      h2 {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.2px;
        color: #2f2e80;
        margin-top: 16px;
        margin-bottom: 0;
      }

      h3 {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 16px;
        text-align: center;
        letter-spacing: -0.05px;
        color: $light-dark-color;
        margin-top: 8px;
        margin-bottom: 0;
      }
    }
  }
}

.action-flex2 {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  .edit {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.117188px;
    text-decoration-line: underline;
    color: #0a74dc;
  }

  &-image {
    width: 20px;
    height: 20px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

tbody > tr:hover {
  .action-flex-image img {
    filter: grayscale(0%);
    opacity: 1;
  }
}

.table-dropdown {
  transform: translateY(2%);
  transition-delay: 0s, 0s, 0.3s;
  position: absolute;
  z-index: 10;
  top: 100%;
  right: 5px;
  min-height: 40px;
  width: 144px;
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 0px 12px rgba(164, 167, 183, 0.2);
  border-radius: 2px;
  padding: 9px 10px;

  &.left {
    left: 0px;
  }

  &-item {
    height: 40px;
    width: 100%;
    padding: 4px 16px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;

    &-image {
      img {
        opacity: 0.7;
      }
    }

    &-text {
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      color: $light-dark-color;
    }

    &:hover {
      background: rgba(13, 47, 161, 0.08);

      &-image {
        img {
          opacity: 1;
          filter: grayscale(100%);
        }
      }
    }

    &:hover img {
      opacity: 1;
      filter: grayscale(100%);
    }
  }
}

.auth-admin th:nth-child(1),
.auth-admin td:nth-child(1),
.auth-admin th:nth-child(2),
.auth-admin td:nth-child(2),
.auth-order th:first-child,
.auth-order td:first-child {
  width: 200%;
  height: 100%;
  padding-left: 0px !important;
  box-sizing: border-box;
  align-items: center;
  display: flex;
}

.auth-admin th:nth-child(2),
.auth-admin td:nth-child(2),
.auth-admin th:nth-child(2),
.auth-admin td:nth-child(2),
.auth-order th:first-child,
.auth-order td:first-child {
  width: 120%;
  height: 100%;
  padding-left: 0px !important;
  box-sizing: border-box;
  align-items: center;
  display: flex;
}

.auth-admin th:nth-child(3),
.auth-admin td:nth-child(3),
.auth-admin th:nth-child(1),
.auth-admin td:nth-child(1),
.auth-order th:first-child,
.auth-order td:first-child {
  width: 100%;
  height: 100%;
  padding-left: 0px !important;
  box-sizing: border-box;
  align-items: center;
  display: flex;
}

.auth-order th:first-child,
.auth-order td:first-child {
  width: 150%;
  height: 100%;
  padding-left: 0px !important;
  box-sizing: border-box;
  align-items: center;
  display: flex;
}

@media screen and (max-width: 768px) {
  .auth-table {
    line-height: 30px;

    thead {
      display: none;
    }

    tbody > tr {
      display: grid;
      grid-gap: 15px;
      box-sizing: border-box;
      background: #fff;
      border-radius: 4px;
      padding: 20px;
      width: 100%;
      height: fit-content;
      margin-bottom: 20px;
    }

    td {
      display: flex;
      justify-content: space-between;
      text-align: left;
      width: 100%;
      overflow-wrap: break-word;
      text-transform: capitalize;
    }
  }

  .auth-admin th:nth-child(2),
  .auth-admin td:nth-child(2),
  .auth-admin th:nth-child(4),
  .auth-admin td:nth-child(4),
  .auth-order th:first-child,
  .auth-order td:first-child {
    width: 100%;
  }
}
