.forgot-password {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: $light-blue;
}

.policy-wrap {
  display: flex;
  justify-content: center;
  margin-top: 70px;
  color: #aaa;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;

  a {
    text-decoration-line: underline;
    color: #303b54;
  }

  span {
    margin: 0 10px;
  }
}

.bottom-wrap {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  color: #aaa;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;

  a {
    text-decoration-line: underline;
    color: #303b54;
  }
}

.bottom-btn {
  display: flex;
  justify-content: center;
}

.terms-of-service,
.privacy-policy {
  height: 100vh;
  width: 100vw;
}

@media screen and (max-width: 768px) {
  .policy-wrap {
    display: grid;
    text-align: center;
    margin-top: 40px;

    a {
      display: block;
      text-decoration-line: underline;
      color: #303b54;
      margin-bottom: 10px;
    }

    span {
      display: none;
    }
  }
}
