.profile-page {
  width: 100%;
  height: 100%;

  &-left {
    width: 100%;
    box-shadow: 2px 0px 6px rgba(229, 229, 229, 0.3);

    &-header {
      h2 {
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 32px;
        color: $deep-dark-color;
        margin-bottom: 0;
      }

      h5 {
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: $header-light;
        margin-top: 8px;
        margin-bottom: 0;
      }
    }

    &-switch {
      margin-top: 76px;
      width: 100%;

      &-div,
      &-div2 {
        height: 64px;
        width: 100%;
        padding: 20px 14px;
        box-sizing: border-box;
        cursor: pointer;

        span {
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
        }
      }

      &-div {
        background: #fff;

        span {
          color: $header-light;
        }
      }

      &-div2 {
        background: rgba(10, 116, 220, 0.1);
        border-right: 3px solid $light-blue;

        span {
          color: $deep-dark-color;
        }
      }
    }
  }

  &-right {
    width: 100%;
    box-sizing: border-box;
  }
}

.user-profile {
  width: 90%;

  &-top,
  &-top2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: fit-content;
    box-sizing: border-box;

    &-left {
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.005em;
      color: $deep-dark-color;
    }

    &-right {
      display: flex;
      align-items: center;
    }
  }

  &-top {
    padding-bottom: 10px;
  }

  &-top2-left {
    margin-top: 48px;
  }

  &-bottom {
    width: 100%;
    display: flex;

    &-left {
      width: 80%;

      &-image {
        display: flex;
        align-items: center;
        position: relative;

        &-div {
          width: 54px;
          height: 54px;
          border-radius: 50%;

          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }

        span {
          margin-left: 32px;
          font-family: "Inter", sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 16px;
          text-decoration-line: underline;
          color: $label-color;
          position: relative;
          cursor: pointer;
          width: 150px;
        }

        .custom-file-input {
          position: absolute;
          opacity: 0;
          left: 70px;
          cursor: pointer;
          width: 150px;
        }

        .custom-file-input::-webkit-file-upload-button {
          visibility: hidden;
        }

        button {
          margin-left: 24px;
          font-family: "Inter", sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          color: $error-color;
          border: none;
          background: #fff;

          &:disabled {
            color: $label-color;
          }
        }
      }

      &-form {
        margin-top: 56px;
        padding-bottom: 40px;
        border-bottom: 1px solid $table-border;
      }

      &-form2 {
        margin-top: 48px;
      }

      &-form-flex {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 24px;
      }

      &-form-flex2 {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 24px;
        margin-top: 32px;
      }

      &-form-flex3 {
        display: grid;
        grid-template-columns: 1fr 2fr;
        grid-gap: 24px;
        margin-top: 32px;
      }
    }

    &-right,
    &-right2 {
      width: 20%;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      padding-bottom: 40px;
    }

    &-right2 {
      padding-bottom: 0px;
    }
  }
}

.profile-spacer {
  margin-top: 48px;
}

.settings {
  padding-top: 20px;

  &-wrapper {
    width: 100%;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr;
    height: fit-content;
    margin-bottom: 24px;
    border-bottom: 1px solid $dull-text;

    &-left {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;

      &-one,
      &-two {
        min-width: 55px;
        margin-right: 24px;
        height: 100%;
        padding: 10px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.005em;
        cursor: pointer;
      }

      &-one {
        color: $table-header;
        border-bottom: 3px solid transparent;
      }

      &-two {
        color: $light-blue;
        border-bottom: 3px solid $light-blue;
      }
    }

    &-right {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-end;
      padding-bottom: 10px;
      box-sizing: border-box;
    }
  }
}

.settings-toggle {
  width: 100%;
  height: fit-content;
  border-top: 1px solid $table-border;
  padding-top: 24px;
  box-sizing: border-box;

  h3 {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: $deep-dark-color;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 768px) {
  .profile-page {
    grid-template-columns: 1fr;
  }

  .user-profile-top-right {
    display: flex;
  }

  .profile-page-left-switch {
    margin-top: 40px;
  }

  .profile-page-left {
    width: 100%;
    max-width: 100%;
    min-height: 0vh;
    box-shadow: none;
  }

  .profile-page-right .user-profile-bottom-form-flex {
    grid-template-columns: 1fr;
  }

  .profile-page-right {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
  }

  .user-profile-bottom {
    display: block;
  }

  .user-profile, .user-profile-bottom-left {
    width: 100%;
  }

  .user-profile-bottom-right,
  .user-profile-bottom-right2 {
    width: 100%;
    justify-content: center;
  }

  .user-profile-bottom-left-form-flex,
  .user-profile-bottom-left-form-flex2 {
    grid-template-columns: 1fr;
  }

  .user-profile-bottom-left-form {
    margin-top: 56px;
    padding-bottom: 40px;
    border-bottom: none;
  }
}
