.input-wrapper {
  .input-container {
    display: flex;
    flex-direction: column;
    position: relative;

    & .input-disabled {
      background: #fff;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      z-index: 2;
      opacity: 0.6;
    }

    .label,
    .text-label,
    .text-label2,
    .text-label3 {
      position: absolute;
      left: 8px;
      top: 3px;
      padding: 0 5px;
      transform: translateY(-50%);
      color: #aaa;
      white-space: nowrap;
      text-overflow: ellipsis;
      background: white;
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: $label-color;
      z-index: 2;
    }

    .text-label2 {
      top: 7px;
    }

    .text-label3 {
      top: -1px;
    }

    .label {
      bottom: 50%;
      margin-bottom: 0;
    }

    .label-input2 {
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: $light-dark-color;
      margin-bottom: 8px;
    }

    .input,
    .error-border {
      box-sizing: border-box;
      border-radius: 4px;
      padding: 16px;
      color: #0b2253;
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: -0.05px;
      height: 48px;
      position: relative;
    }

    .input {
      background: #ffffff;
      border: 1px solid $table-header;
      box-sizing: border-box;
      border-radius: 4px;
      height: 48px;
      width: 100%;
    }

    .input::placeholder {
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      color: $table-header;
    }

    .input:focus,
    .textarea:focus {
      border: 1px solid $light-blue;
    }

    .textarea,
    .error-border2 {
      min-height: 128px;
      width: 100%;
      overflow-y: auto;
      background: #ffffff;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 16px;
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: $light-dark-color;
      display: flex;
      align-items: center;
    }

    .showhide-btn {
      position: absolute;
      cursor: pointer;
      right: 16px;
      bottom: 16px;
    }

    .textarea {
      border: 1px solid $table-header;
    }

    .textarea::placeholder {
      color: #b2b2b2;
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
    }

    .error-border,
    .error-border2 {
      border: 1px solid #d84435;
    }

    .readonly-input {
      background: $dark-background;
      color: $label-color;

      &:focus {
        border: 1px solid $dull-text;
      }
    }
  }
}

.form-grid {
  margin-top: 30px;
}

.top-spacer {
  margin-top: 32px;
}

.top-spacer2 {
  margin-top: 40px;
}

.form-grid2,
.form-grid3 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;

  &.grid__gap-lg {
    grid-gap: 33px;
  }
}

.form-grid4 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 24px;
  margin-top: 30px;
}

.form-grid2 {
  margin-top: 40px;
  position: relative;

  .notify {
    position: absolute;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 24px;
    color: #d0247e;
  }
}

.cancel-error,
.cancel-error2 {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #d84435;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  font-size: 10px;
  cursor: pointer;
}

.cancel-error {
  right: 16px;
  top: 15px;
}

.cancel-error2 {
  right: 16px;
  bottom: 15px;
}

.errors {
  display: block;
  margin-top: 8px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  color: #d84435;
  font-weight: 400;
  transition: all 300ms ease-in-out;
}

.errors::first-letter {
  text-transform: capitalize;
}

.Dropdown-option {
  box-sizing: border-box;
  cursor: pointer;
  color: $deep-blue !important;
  display: block;
  padding: 8px 10px !important;
  height: 40px !important;
  font-family: "Inter", sans-serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 24px !important;
}

.Dropdown-option:hover {
  background: $dark-background !important;
}

.Dropdown-menu {
  background-color: white;
  box-sizing: border-box;
  margin-top: 4px !important;
  max-height: 600px !important;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
  border: 1px solid $dull-text !important;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
    0px 0px 1px rgba(0, 0, 0, 0.04) !important;
  border-radius: 4px !important;
}

.Dropdown-control {
  position: relative;
  overflow: hidden;
  background-color: white;
  box-sizing: border-box;
  border: 1px solid $table-header !important;
  border-radius: 4px !important;
  cursor: pointer !important;
  outline: none;
  padding: 12px 16px !important;
  transition: all 200ms ease;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.117188px;
  color: $deep-dark-color !important;
  height: 48px !important;
}

.Dropdown-control:hover {
  background: none !important;
}

.Dropdown-arrow {
  content: url(../../assets/dropdown-select.svg) !important;
  display: inline-block !important;
  position: absolute;
  right: 10px;
  top: 20px !important;
  border-width: 0 !important;
  width: 13px !important;
  height: 8px !important;
  border-right: none !important;
  border-top: none !important;
  margin-right: 0.5em !important;
  cursor: pointer;
}

.is-open .Dropdown-arrow {
  border-color: none !important;
  transform: rotate(180deg) !important;
  top: 20px !important;
}

.Dropdown-option:hover {
  background: rgba(13, 47, 161, 0.08);
}

.Dropdown-option {
  color: $deep-dark-color;
}

.Dropdown-placeholder {
  color: #ccc !important;
}

.is-selected {
  background: none !important;
  color: $deep-dark-color !important;
}

.multitags {
  position: relative;

  &-icon {
    position: absolute;
    bottom: 16px;
    right: 12px;
    width: 19px;
    height: 19px;
    cursor: pointer;
    transform: rotate(-45deg);

    img {
      width: 100%;
      height: 100%;
    }
  }

  .multiselect-arrw {
    content: url("../../assets/dropdown-select.svg") !important;
    display: inline-block !important;
    position: absolute;
    right: 10px;
    top: 20px !important;
    border-width: 0 !important;
    width: 13px !important;
    height: 8px !important;
    border-right: none !important;
    border-top: none !important;
    margin-right: 0.5em !important;
    cursor: pointer;
  }
}

.react-tagsinput {
  margin-top: 8px !important;
  padding: 12px 16px !important;
  background: #ffffff !important;
  border: 1px solid $table-header !important;
  min-height: 48px !important;
  width: 100% !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;
  display: flex;
  align-items: center;
}

.react-tagsinput--focused {
  border-color: $light-blue !important;
}

.react-tagsinput-tag {
  width: fit-content !important;
  height: 26px !important;
  background: rgba(177, 188, 199, 0.3) !important;
  border-radius: 20px !important;
  border: none !important;
  display: inline-flex !important;
  align-items: center !important;
  font-family: "Inter", sans-serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #6d7a98 !important;
  margin-bottom: 5px;
  margin-right: 8px !important;
  padding: 4px 8px !important;
}

.react-tagsinput-input {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  padding: 5px;
  width: fit-content !important;
  background: transparent !important;
  box-sizing: border-box !important;
  outline: none !important;
  border: 0 !important;
  font-family: "Inter", sans-serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  color: #0b2253 !important;

  &::placeholder {
    font-size: 16px !important;
    line-height: 25px !important;
    letter-spacing: -0.05px !important;
    color: $table-header !important;
  }
}

.react-tagsinput-remove {
  cursor: pointer;
  font-family: "Inter", sans-serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  line-height: 16px !important;
  letter-spacing: 0.4px !important;
  margin-left: 10px !important;
}

.multidrop-icon {
  color: $label-color;
  font-size: 20px;
  margin-left: 7px;
  margin-bottom: 4px;
  cursor: pointer;
}

.highlightOption {
  color: $deep-blue !important;
  background: none !important;
  display: block;
  padding: 8px 10px !important;
  height: 40px !important;
  font-family: "Inter", sans-serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 24px !important;
}

.highlightOption:hover,
.multiSelectContainer li:hover {
  background: #f9f9f9 !important;
  color: $deep-blue !important;
}

.multiSelectContainer {
  border: 2px solid rgba(177, 188, 199, 0.4) !important;
  background: #ffffff !important;
  border: 1px solid #a4a7b7 !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;
  width: 100% !important;
}

.multiSelectContainer input::placeholder {
  font-family: "Inter", sans-serif !important;
  font-size: 14px !important;
  line-height: 24px !important;
  letter-spacing: -0.05px !important;
  color: $table-header !important;
}

.checkboxes {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 20px;
  align-items: center;
  margin-bottom: 30px;

  &-title {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 16px;
    letter-spacing: 0.002em;
    color: $deep-dark-color;
    margin-bottom: 32px;
  }

  input[type="checkbox"] {
    cursor: pointer;
  }

  label {
    display: flex;
    align-items: center;
    grid-column-gap: 10px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: $light-dark-color;
    text-transform: capitalize;
  }
}

@media screen and (max-width: 768px) {
  .form-grid2,
  .form-grid3 {
    grid-template-columns: 1fr;
    grid-gap: 24px;
  }
  .form-grid4 {
    grid-template-columns: 1fr 1fr;
  }
}


.pin-to-bottom {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translate(-50%, -50%);
}