.single-users {
  width: 100%;
  height: 100%;
}

.top-wrap {
  display: flex;
  flex-direction: column;
  margin-bottom: 3px;
  width: 100%;
}

.top-wrap-top {
  display: flex;
  justify-content: space-between;
  min-height: 48px;
  width: 100%;
  margin: 20px 0;
}

.client {
  width: 100%;
  height: 100%;

  &-header {
    &-top {
      display: flex;
      align-items: baseline;

      &-image {
        width: 56px;
        height: 56px;
        margin-right: 16px;

        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }

      &-text {
        text-transform: capitalize;

        h2 {
          font-family: "Inter", sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 32px;
          line-height: 24px;
          color: $deep-dark-color;
          margin-bottom: 4px;
        }

        div {
          font-family: "Inter", sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 20px;
          line-height: 24px;
          color: #979797;
          margin-top: 8px;
        }

        p {
          font-family: "Inter", sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 24px;
          color: #00dcb4;
          margin-bottom: 6px;
        }
      }
    }
  }
}

.info-wrap {
  display: grid;
  grid-template-columns: 0.4fr 0.7fr;
  grid-gap: 30px;
  border-top: 1px solid rgba(229, 229, 229, 0.9);

  &-left {
    border-right: 1px solid rgba(229, 229, 229, 0.9);
    height: 100%;
    padding-right: 30px;

    .info {
      h4 {
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: $deep-dark-color;
        margin: 30px 0 20px 0;
      }

      &-data {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap: 15px;

        &-right {
          text-align: right;
          font-family: "Inter", sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          color: #a4a7b7;
        }

        &-left {
          font-family: "Inter", sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          color: $deep-dark-color;
        }
      }
    }
  }

  &-right {
    width: 100%;
    box-sizing: border-box;
    height: 100%;

    .info {
      .carousel-icon {
        text-align: right;
        margin-top: 15px;
      }

      h4 {
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: $deep-dark-color;
        margin: 30px 0 20px 0;
      }

      &-top {
        display: flex;
        align-items: baseline;
        justify-content: space-between;

        span {
          font-family: "Inter", sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 24px;
          text-decoration-line: underline;
          color: #0a74dc;
          cursor: pointer;
        }
      }

      &-flex {
        display: flex;
        display: flex;
        align-items: center;
        grid-column-gap: 20px;

        span {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}

.session {
  width: 100%;
  min-height: 110px;
  background: #f9f9f9;
  border: 1px solid rgba(10, 116, 220, 0.2);
  box-sizing: border-box;
  border-radius: 8px;
  padding: 12px 8px;
  display: flex;
  flex-flow: wrap;
}

.session-left {
  width: 48px;
  height: 48px;
  // background: #ffe1f1;
  border-radius: 50px;
  margin-right: 20px;

  img {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
  }
}

.session-right {
  .title {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: $deep-dark-color;
  }

  .name {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #979797;
    text-transform: capitalize;
  }

  .date {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: -0.035em;
    color: #b2b2b2;
    margin-top: 10px;
  }
}

@media screen and (max-width: 768px) {
  .info-wrap {
    grid-template-columns: 1fr;
  }

  .info-wrap-right {
    padding-left: 0px;
  }

  .session {
    display: block;
    // width: 100%;
    // list-style: none;
    // height: 110px;
    // background: #f9f9f9;
    // border: 1px solid rgba(10, 116, 220, 0.2);
    // box-sizing: border-box;
    // border-radius: 8px;
    // justify-content: space-evenly;
    // padding: 12px 8px;
  }
  .top-wrap-top {
    display: grid;
  }
  .client {
    &-header {
      &-top {
        margin-bottom: 20px;
        &-text {
          h2 {
            font-size: 25px;
          }
        }
      }
    }
  }
  .hide-carousel {
    display: none;
  }
}

.bghjHX {
  margin-left: 0 !important;
}
