.search-div {
  width: 347px;
  height: 44px;
  border: 1px solid $border-color;
  box-sizing: border-box;
  border-radius: 4px;
  background: #fff;
  padding: 10px 16px 10px 40px;
  background-position: left 16px center;
  background-repeat: no-repeat;
  background-image: url("../../assets/search.svg");
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: $deep-dark-color;

  &::placeholder {
    margin-left: 10px;
    color: $light-dark-color;
    font-size: 14px;
  }
}

.filter-div {
  width: 103px;
  height: 44px;
  background: #ffffff;
  border: 1px solid $border-color;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  padding: 12px 16px;
  align-items: center;
  cursor: pointer;
  justify-content: center;

  &-text {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.4px;
    margin-right: 9px;
    color: $light-dark-color;
  }

  &-img {
    width: 15px;
    height: 13px;

    img {
      width: 100%;
      height: 100%;
      vertical-align: inherit;
    }
  }

  &-img2 {
    width: 20px;
    height: 20px;

    img {
      vertical-align: inherit;
    }
  }
}

.flex-options {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.flex-options2 {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &-reorder {
    display: flex;
    align-items: center;
    margin-right: 20px;

    .text {
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: $light-dark-color;
      margin-right: 16px;
    }
  }
}

.flex-options2-reorder {
  .Dropdown-control {
    height: 42px !important;
    min-width: 202px !important;
    width: 100% !important;
    display: flex;
    align-items: center;
    padding: 8px 52px 8px 10px !important;
  }

  .Dropdown-arrow {
    display: inline-block !important;
    position: absolute;
    right: 5px !important;
    top: 17px !important;
    border-width: 0 !important;
    width: 13px !important;
    height: 8px !important;
    border-right: none !important;
    border-top: none !important;
    margin-right: 0.5em !important;
    cursor: pointer;
  }
}

.filter-modal-btn {
  margin-top: 40px;
}

.messages-status {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.002em;
  color: $deep-dark-color;
  width: 100%;
  min-height: 50px;

  &-label {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: $deep-dark-color;
    margin-bottom: 16px;
  }

  &-group {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 22px;

    &-each {
      cursor: pointer;
      height: 15px;
      min-width: 68px;
      display: flex;
      align-items: center;

      &-image {
        width: 15px;
        height: 15px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      &-label {
        margin-left: 8px;
        font-weight: normal;
      }
    }
  }
}

.toggle-button {
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 26px;
  width: 100%;

  h2 {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: $label-color;
    margin-bottom: 0;
  }

  label {
    display: flex;
    align-items: center;

    span {
      margin-left: 18px;
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: $light-dark-color;
    }
  }
}

.date-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;

  .label2 {
    position: absolute;
    left: 8px;
    bottom: 50%;
    top: 3px;
    padding: 0 5px;
    transform: translateY(-50%);
    color: #aaa;
    white-space: nowrap;
    text-overflow: ellipsis;
    background: white;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: $label-color;
    z-index: 3;
    margin-bottom: 0;
  }
}

.filter-label {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: $light-dark-color;
  margin-bottom: 8px;
  min-height: 20px;
}

.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
}

.react-datepicker-popper {
  z-index: 10 !important;
}

.react-datepicker__navigation-icon--next::before {
  top: 10px !important;
}

.react-datepicker__navigation-icon--previous::before {
  top: 12px !important;
}

.react-datepicker__header {
  padding: 15px 0 !important;
}

.react-datepicker__input-container input,
.MuiInputBase-input {
  width: 100% !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;
  color: $deep-dark-color !important;
  font-family: "Inter", sans-serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 16px !important;
  letter-spacing: -0.05px !important;
  position: relative !important;
  border: 1px solid $dull-text !important;
  padding: 12px 16px !important;
  background-position: right 13px center !important;
  background-repeat: no-repeat !important;

  &::placeholder {
    font-size: 16px !important;
    line-height: 24px !important;
    letter-spacing: -0.117188px !important;
    color: $table-header !important;
  }

  &:focus {
    border: 1px solid $light-blue !important;
  }
}

.react-datepicker__input-container input {
  background-image: url("../../assets/calendar.svg") !important;
}

// .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
//   left: 50% !important;
// }

.MuiInputBase-input {
  background-image: url("../../assets/clock.svg") !important;
}

.MuiInputBase-input {
  font: inherit;
  color: currentColor;
  width: 100%;
  border: 0;
  height: 45px !important;
  margin: 0;
  display: block;
  padding: 6px 0 7px;
  min-width: 0;
  background: none;
  box-sizing: content-box;
  animation-name: none !important;
  letter-spacing: inherit;
  animation-duration: 10ms;
  -webkit-tap-highlight-color: transparent;
}

label + .MuiInput-formControl {
  margin-top: 0px !important;
}

.MuiInputLabel-formControl {
  display: none !important;
}

.makeStyles-textField-2 {
  width: 100% !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.MuiInput-underline:before {
  left: 0;
  right: 0;
  bottom: 0;
  content: none !important;
  position: absolute;
  transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  pointer-events: none;
}

.MuiInputAdornment-positionEnd {
  margin-left: -43px !important;
  opacity: 0 !important;
}

.MuiFormControl-marginNormal {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.react-datepicker__navigation {
  align-items: flex-start !important;
}

@media screen and (max-width: 768px) {
  .flex-options {
    display: flex;
    margin-top: 10px;
  }

  .search-div {
    width: 100% !important;
  }

  .filter-div {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}
