.prescription-details {
  background-color: #e5e5e5;
  position: relative;
  // height: 100vh;
  padding-top: 7rem;
  padding-bottom: 7rem;
  .cancel-icon {
    position: absolute;
    right: 5rem;
    top: 2rem;
    cursor: pointer;
    img {
      width: 4rem;
    }
  }
  .page-content {
    background: white;
    padding: 3.5rem 4rem;
    margin: 5rem;
    .date {
      display: flex;
      .date-issued {
        text-decoration: underline;
        margin-right: 2rem;
      }
    }
    .client-details {
      background: #eff3f8;
      padding: 1.5rem 3rem;
      display: flex;
    }
    .left {
      flex: 0 0 50%;
      margin-top: 3rem;
      margin-right: auto;
      .text-content {
        display: flex;
        p {
          line-height: 0.7;
        }
        .presc-ref {
          font-weight: bold;
        }
        .name {
          margin-right: 1rem;
        }
        .content {
          font-weight: bold;
        }
      }

      .text-content:first-child {
        margin-bottom: 1.5rem;
      }
    }
    .right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      flex: 0 0 50%;
      .text-content {
        display: flex;
        p {
          line-height: 0.7;
        }
        .presc-ref {
          font-weight: bold;
        }
        .name {
          font-weight: bold;
          margin-right: 1rem;
        }
      }
      img {
        margin-bottom: 2rem;
      }
    }
  }
  table {
    width: 100%;
    margin-top: 4rem;

    td,
    th {
      border: 2px solid #000;
      text-align: center;
      padding: 8px;
    }
    th {
      font-weight: bold;
    }
  }
  .prescribed-by {
    margin-top: 20rem;
    padding: 0 3rem;
    h2 {
      font-size: 1.5rem;
      text-decoration: underline;
    }
    h3 {
      margin-bottom: 2rem;
    }
    p {
      color: #2e3192;
      line-height: 0.7;
    }
  }
}

.prescription-table-button {
  border: 1px solid rgb(77, 128, 211);
  width: 60%;
  display: flex;
  justify-content: center;
  border-radius: 1rem;
  background-color: #5f7eb118;
  height: 4rem;
  font-size: 1.2rem;

  p {
    margin-top: 0.8rem;
    color: $light-blue;
  }
}

.prescription-status {
  // padding: .5rem 1rem;
  display: flex;
  justify-content: center;
  border-radius: 1.5rem;
}

.prescription-draft {
  background-color: #577fbf18;
}

.prescription-published {
  background-color: #6ee67053;
}

@media screen and (max-width: 1100px) {
  .prescription-details {
    .cancel-icon {
      right: 1rem;
    }
    .page-content {
      margin: 1rem;
      padding: 3.5rem 1.5rem;
    }
  }
}
@media screen and (max-width: 970px) {
  .prescription-details .page-content .left .text-content p {
    font-size: 1rem;
  }
  .prescription-details .page-content .right .text-content p {
    font-size: 1rem;
  }
  .prescription-details {
    .cancel-icon {
      img {
        width: 2rem;
      }
    }
  }
  .prescription-details .page-content .right img {
    width: 7rem;
  }
}

@media screen and (max-width: 550px) {
  .prescription-details .page-content .left {
    flex: 0 0 30%;
  }
  .prescription-details {
    
    .page-content {
      padding: 3.5rem .5rem;
    }
  }
  .prescription-details .page-content .client-details {
    padding: 1rem 1.5rem;
  }
}
