.add-admin {
  display: grid;
  justify-items: center;
  grid-row-gap: 10px;
  &-icon {
    width: 67.05px;
    height: 52px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  &-text {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: $deep-dark-color;
  }
  &-button {
    margin: 10px 0;
    display: flex;
    justify-content: flex-end;
  }
}

.role-checkbox-block {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;

  &-item {
    display: flex;
    align-items: center;
    height: 45px;
  }
}

.role-checkbox {
  margin: 25px 0px;

  input[type="checkbox"] {
    width: 16px;
    height: 16px;
    margin-right: 13px;
    cursor: pointer;
    font-size: 8px;
    visibility: hidden;
    width: 12px;
    height: 12px;
  }

  input[type="checkbox"]:after {
    content: " ";
    display: inline-block;
    color: #fff;
    width: 16px;
    height: 16px;
    visibility: visible;
    background: #fff;
    border: 1px solid #d7dce0;
    box-sizing: border-box;
    border-radius: 50%;
  }

  input[type="checkbox"]:checked:after {
    content: "\2713";
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background: #0a74dc;
  }

  &-title {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: #0b2253;
  }

  &-title2 {
    margin-top: 34px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 16px;
    letter-spacing: 0.002em;
    color: $deep-dark-color;
    margin-bottom: 15px;
  }

  label {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: $light-dark-color;
    text-transform: capitalize;
  }
}
