.chats {
  width: 100%;
  padding: 0 2rem;
  display: grid;
  grid-template-columns: 1fr 2fr;
  height: 100vh;

  &-left {
    width: 100%;
    min-width: 434px;
    overflow: auto;
    max-height: 100vh;
    background: #fff;
    padding: 0 1rem;
    border: 1px solid #e5e5e5;
    border-radius: 5px;

    .chats-top {
      width: 100%;
      height: fit-content;
      box-sizing: border-box;
      padding: 40px 40px;
      padding-bottom: 0;
    }

    &-switch {
      margin-top: 32px;
    }

    .all-chats {
      display: grid;
      width: 100%;
      padding-bottom: 50px;

      &-single {
        min-height: 80px;
        padding: 24px 40px;
        box-sizing: border-box;
        width: 100%;
        display: flex;
        align-items: center;
        // border-bottom: 1px solid #e5e5e5;
        cursor: pointer;

        &-image {
          width: 52px;
          height: 52px;
          border-radius: 50%;

          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }

        &-text {
          margin-left: 21px;
          width: 80%;

          &-name {
            font-family: "Inter", sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: #000000;
            text-transform: capitalize;
          }

          &-message {
            margin-top: 4px;
            font-family: "Inter", sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 24px;
            color: #393939;
          }
        }

        &-figures {
          display: flex;
          align-items: center;
          flex-direction: column;

          &-time {
            font-family: "Inter", sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 24px;
            color: #a4a7b7;
          }

          &-msg {
            margin-top: 2px;
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background: #00dcb4;
            font-family: "Inter", sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 24px;
            color: #ffffff;
          }
        }
      }

      &-single:hover {
        background: rgba(13, 47, 161, 0.05);
      }

      &-single2 {
        // border-right: 4px solid #0a74dc;
        background: rgba(13, 47, 161, 0.05);
      }
    }
  }

  &-left::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  &-left {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }

  &-right {
    width: 95%;
    height: 100%;
    background: #fff;
    position: relative;
    margin-left: 2rem;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    overflow: hidden;
    .specialist-info {
      width: 100%;
      margin-top: 2rem;
      display: flex;
      border-top: 1px solid #e5e5e5;
      border-bottom: 1px solid #e5e5e5;
      padding: 2rem 0;
      font-size: 1.2rem;
      display: flex;
      justify-content: center;
    }

    &-header {
      width: 100%;
      min-height: 120px;
      padding: 0 40px;
      padding-top: 23px;
      padding-bottom: 24px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      background: #fff;

      &-left {
        display: flex;
        align-items: center;
        // border-bottom: 1px solid #e5e5e5;

        &-image {
          width: 42px;
          height: 42px;
          border-radius: 50%;

          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }

        &-details {
          font-family: "Inter", sans-serif;
          font-style: normal;
          font-weight: 500;
          margin-left: 24px;

          .top {
            font-size: 14px;
            line-height: 14px;
            color: $deep-dark-color;
            text-transform: capitalize;
          }

          .bottom {
            margin-top: 2px;
            font-size: 13px;
            line-height: 24px;
            color: #a4a7b7;
            text-transform: capitalize;
          }
        }
      }
    }

    &-details {
      min-height: 50vh;
      box-sizing: border-box;
      padding-top: 40px;
      display: flex;
      flex-direction: column;
      background: #fff;

      &-title {
        width: fit-content;
        min-width: 300px;
        padding: 10px 50px;
        box-sizing: border-box;
        min-height: 50px;
        background: rgba(229, 229, 229, 0.3);
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        align-self: center;
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        color: #979797;
        text-align: center;
        text-transform: capitalize;
      }

      &-history,
      &-history2 {
        margin-top: 70px;
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
        color: #979797;
        align-self: center;
      }

      &-history2 {
        margin-top: 24px;
      }
    }

    &-details2,
    &-details3 {
      width: 100%;
      min-height: 60vh;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
    }

    &-details3 {
      width: 100%;
      min-height: 90vh;
    }
  }
}

.chats2 {
  padding: 32px 40px;
  padding-bottom: 50px;
  box-sizing: border-box;
}

#chat-check,
.chats-back {
  display: none;
}

.chats-margin {
  margin-right: 40px;
}

.chats-right-web {
  display: block;
}

.chats-right-mobile {
  display: none;
}

.chats-padding {
  padding-left: 40px;
}

.chats-minWidth {
  min-width: 100%;
}

.chats-modal-text {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: $deep-dark-color;
  margin-bottom: 48px;
}

@media screen and (max-width: 768px) {
  .chats {
    grid-template-columns: 1fr;
    position: relative;
  }

  .chats-left {
    min-width: 100% !important;
  }

  .chats-left .chats-top {
    padding: 20px;
  }

  .chats-margin {
    margin-right: 40px;
  }

  .chats-padding {
    padding-left: 20px;
  }

  .chats-right-web {
    display: none;
  }

  .chats-right-header {
    padding: 0 20px;
    padding-top: 40px;
    padding-bottom: 24px;
    align-items: center;
    display: block;
  }

  .chats-back {
    display: flex;
    margin-right: 10px;
    cursor: pointer;
  }

  #chat-check:checked ~ .chats-right-mobile {
    position: absolute;
    display: block;
    width: 100%;
    left: 0;
    top: 0;
  }

  .chats-right-details-title {
    min-width: 200px;
    width: 100%;
  }

  .chats-right-details {
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
  }

  .chats2 {
    padding: 16px 20px;
    padding-bottom: 50px;
    box-sizing: border-box;
  }

  .chats-right-header-left {
    margin-bottom: 20px;
  }
}

.chat-search {
  border: none;
  background-color: $gray-300;
  background-image: url("../../assets/search.svg");
  background-repeat: no-repeat;
  background-position: left 16px center;
  padding: 10px 16px 10px 40px;
  border-radius: 5px;
}

.chat-left-tabs {
  margin-left: -3rem;
}

.custom-transfer-user-button {
  border: 1px solid rgba(10, 116, 220, 0.6);
  background-color: rgba(10, 116, 220, 0.08);
  padding: 0.5rem 1.5rem;
  color: #0a74dc;
}
