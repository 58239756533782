.modal,
.modal2 {
  z-index: 1000;
  position: fixed;
  left: 0;
  opacity: 1;
  right: 0;
  top: 0;
  transition: all .3s;
}

.modal-content {
  z-index: 1000;
  transform: translateX("100%");
  background-color: #fff;
  max-width: 989px;
  height: 100%;
  box-sizing: border-box;
  position: fixed;
  // overflow: auto;
  margin-bottom: 50px;
  transition: all .3s;

  &-header {
    height: 80px;
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 0px;
    box-sizing: border-box;
    margin: auto;
    border-bottom: 1px solid #78767665;
    // background: rgba(13, 47, 161, 0.08);

    h1 {
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      color: $deep-dark-color;
      margin-bottom: 0;
    }
  }

  &-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 40px 50px;
    padding-bottom: 100px;
    box-sizing: border-box;

    &-form {
      width: 100%;
    }
  }

  &-container2 {
    display: flex;
    margin-top: 100px;
    flex-direction: column;
    padding: 24px 55px;
    box-sizing: border-box;
  }
}

// Delete Modal
.modal2 {
  display: flex;
  justify-content: center;
  align-items: center;

  .modal2-content {
    background-color: #fff;
    max-width: 463px;
    min-height: 328px;
    margin: auto;
    box-sizing: border-box;
    position: relative;

    .top-delete-modal {
      padding: 56px 46px;
      padding-bottom: 0;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
    }

    &-header {
      font-family: "Inter", sans-serif;
      color: #303b54;
      font-style: italic;
      font-weight: 500;
      font-size: 24px;
      line-height: 48px;
      text-align: center;
    }

    hr {
      width: 100%;
      height: 0px;
      border: 1px solid $table-border;
    }

    &-text {
      min-height: 100px;
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: $deep-dark-color;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-button {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      height: 64px;
      width: 100%;
      align-items: center;
      position: absolute;
      bottom: 0;

      span {
        cursor: pointer;
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.2px;
        color: $light-dark-color;
        margin-right: 20px;
      }

      .button1,
      .button2 {
        font-family: "Inter", sans-serif;
        box-sizing: border-box;
        padding: 20px 0px;
        border: none;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.2px;
        width: 100%;
        height: 64px;

        &:disabled {
          opacity: 0.32;
          cursor: not-allowed;
          background: $error-color;
          color: #fff;
        }
      }

      .button1 {
        background: $error-color;
        color: #fff;
      }

      .button2 {
        background: rgba(229, 229, 229, 0.3);
        color: $table-header;
      }
    }
  }
}

.close-modal {
  color: $table-header;
  float: right;
  font-size: 30px !important;
}

.close-modal:hover,
.close-modal:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .modal-content,
  .modal2 .modal2-content {
    max-width: 100% !important;
    width: 100% !important;

    &-container {
      height: 100%;
      display: flex;
      flex-direction: column;
      padding: 30px;
      box-sizing: border-box;

      &-form {
        width: 100%;
      }
    }
  }
}
