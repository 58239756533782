.auth-wrap {
  width: 100%;
  align-items: center;
  display: flex;
  padding-top: 97px;
  flex-direction: column;
  justify-content: center;
}

.brand-logo {
  width: 93px;
  height: 36px;
  margin-bottom: 64px;

  img {
    width: 100%;
    height: 100%;
  }
}

.auth-form {
  width: 467px;
  min-height: 480px;
  padding: 48px 66px;
  box-sizing: border-box;
  background-color: #ffffff;
  justify-self: center;
  background: #ffffff;
  border: 1px solid rgba(164, 167, 183, 0.3);
  box-sizing: border-box;
  border-radius: 8px;
}

.auth-form2 {
  min-height: 620px;
  padding: 48px 66px;
  box-sizing: border-box;
  background-color: #ffffff;
  justify-self: center;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 8px;
}
.auth-form3 {
  min-height: 620px;
  width: 70vw;
  padding: 48px 66px;
  box-sizing: border-box;
  background-color: #ffffff;
  justify-self: center;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 8px;
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.inbox {
  text-align: center;
  width: 143px;
  height: 140px;
  margin-top: 40px;

  img {
    width: 100%;
    height: 100%;
  }
}

.auth-header {
  margin-bottom: 60px;
  h1 {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: $deep-dark-color;
    margin-bottom: 0;
  }

  h4 {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: $light-dark-color;
    margin-top: 8px;
    margin-bottom: 0;
  }
}

.auth-header2 {
  h1 {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 32px;
    color: $deep-dark-color;
    margin-bottom: 0;
  }

  h4 {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: $header-light;
    margin-top: 8px;
    margin-bottom: 0;
  }
}

.page-headers {
  display: flex;
  flex-direction: column;
  margin-bottom: 3px;
  width: 100%;

  &-top {
    display: flex;
    justify-content: space-between;
    min-height: 48px;
    width: 100%;
    margin-bottom: 40px;
  }

  .count {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: $deep-dark-color;
  }
}

.switch-wrapper {
  width: 100%;
  height: 100%;

  &-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 40px;
    margin-bottom: 24px;

    &-link {
      // min-width: 390px;
      height: 100%;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #e5e5e5;

      &-one,
      &-two {
        width: auto;
        height: 100%;
        padding: 10px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        cursor: pointer;
        margin: 0 10px;
        // margin-right: 20px;
      }

      &-one {
        color: $table-header;
      }

      &-two {
        color: #0a74dc;
        border-bottom: 2px solid #0a74dc;
      }
    }

    &-right {
      display: flex;
    }
  }
}

@media (min-width: 500px) and (max-width: 1150px) {
  .auth-form {
    width: 50%;
  }
}

@media screen and (max-width: 768px) {
  .auth-form {
    width: 100%;
  }

  .auth-wrap {
    padding: 20px;
    box-sizing: border-box;
    padding-top: 97px;
  }

  .page-headers {
    display: grid;
    justify-content: space-between;
    margin-bottom: 24px;
    grid-gap: 10px;
    height: fit-content;
    width: 100%;
  }

  .page-headers-top {
    display: grid;
  }

  .switch-wrapper {
    &-top {
      display: grid;
      justify-content: initial;

      &-right {
        display: grid;
        grid-gap: 10px;
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
  }

  .switch-wrapper-top-link {
    min-width: 100% !important;
    display: grid;
  }

  .switch-wrapper-top-link-one,
  .switch-wrapper-top-link-two {
    justify-content: flex-start;
  }
}
