.notfound {
  width: 100%;
  height: 100vh;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &-container {
    width: 461px;
    min-height: 343px;
    text-align: center;

    &-image {
      width: 271px;
      height: 150px;
      margin: 0 auto;

      img {
        width: 100%;
        height: 100%;
      }
    }

    h1 {
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 46px;
      line-height: 48px;
      color: #303b54;
      margin-bottom: 0;
    }

    h3 {
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      margin-top: 24px;
      color: $deep-dark-color;
      margin-bottom: 0;
    }
  }
}

@media (min-width: 500px) and (max-width: 1150px) {
  .notfound {
    padding: 20px;
    box-sizing: border-box;

    &-container {
      width: 70%;
    }
  }
}

@media screen and (max-width: 768px) {
  .notfound {
    padding: 20px;
    box-sizing: border-box;

    &-container {
      width: 100%;
    }
  }
}
