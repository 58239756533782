@import './variables';

.text {
  &-underline {
    text-decoration: underline;
  }

  &-blue {
    color: $light-blue;
  }

  &-red {
    color: $error-color;
  }

  &-capitalize {
    text-transform: capitalize;
  }

  &-center {
    text-align: center;
  }

  &-grey {
    color: $table-header;
  }
}

.cursor {
  &-pointer {
    cursor: pointer;
  }
}

.u {
  &-above {
    position: relative;
    z-index: 20;
  }
}

.draggable {
  &-container {
    position: relative;
  }

  &-btn {
    position: absolute;
    padding: 10px 5px;
    background: transparent;
    font-size: 1rem;
    color: $deep-dark-color;
    top: 0;
    right: 0;
    width: 23px;
    height: 100%;
    z-index: 1;
    box-shadow: -3px 0px 4px 0px rgba(0, 0, 0, 0.05);
    border-left: 1px solid #e5e5e5;
  }

  &-icon {
    width: 13px;
    height: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.table-disabled {
  position: relative;

  &:after {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transition: 0.5s all ease-out;
    background: transparent;
    z-index: 20;
    pointer-events: none;
  }

  &.active:after {
    backdrop-filter: blur(1px);
    background: rgba(255, 255, 255, 0.5);
    pointer-events: auto;
  }
}

.custom__dropdown {
  position: relative;

  &-option {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 110%;
    right: 0;
    pointer-events: none;
    transition: 0.3s all ease-out;

    &.active {
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
    }
  }
}

.rdrDateRangePickerWrapper.date-range-picker {
  box-shadow: 0px 4px 7px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
}

.date-range-picker-btn {
  background: #3d91ff;
  outline: none;
  border: none;
  border-radius: 5px;
  color: #fff;
  margin-top: -12px;
  margin-right: 7px;
  padding: 2px 20px;
  box-shadow: 0px 4px 7px 4px rgba(0, 0, 0, 0.1);
  font-weight: bolder;
}
